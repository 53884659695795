<template>
  <div v-if="headerDisply">
   <PageContainerLayout>
    <router-view />
    <notifications group="foo" position="top right" />
    </PageContainerLayout>
  </div>
  <div v-else>
     <router-view />
    <notifications group="foo" position="top right" />
  </div>

</template>

<script>
import { ref,computed,watchEffect } from 'vue'
import config1 from "@/helpers/config.js";
import {storeToRefs} from "pinia";
import { uselayoutStore } from '@/store/layoutstore'
import PageContainerLayout from "@/components/layout/PageContainerLayout.vue";
import { useRouter,useRoute } from 'vue-router';

export default {
  components: {
    PageContainerLayout
  },
    setup() {
       const route = useRoute()
    const router = useRouter()
    const layoutStore = uselayoutStore()
    const headerDetails1=ref([]);
     const headerDisply=ref(false);
    const config=ref(config1);
    const { headerDetails } = storeToRefs(layoutStore)
    const headerDetailsGetter= computed(() => headerDetails.value);
     const getFavIcon = () => {
      const favicon = document.getElementById("favicon");
      if (headerDetailsGetter.value.meta_header != undefined) {
        if (headerDetailsGetter.value.meta_header.favicon_url != undefined) {
          if (
            headerDetailsGetter.value.meta_header.favicon_url.includes("http")
          ) {
            favicon.href = headerDetailsGetter.value.meta_header.favicon_url;
          } else {
            favicon.href =
              "/backend" + headerDetailsGetter.value.meta_header.favicon_url;
          }

          if (headerDetailsGetter.value.edition == "cloud") {
            var selectedColor =
              headerDetailsGetter.value.meta_header.adminCloudColor;
          } else if (headerDetailsGetter.value.edition == "campus") {
            var selectedColor =
              headerDetailsGetter.value.meta_header.adminCampusColor;
          } else {
            var selectedColor =
              headerDetailsGetter.value.meta_header.adminIspColor;
          }
          const rootCssVariable = document.querySelector(":root");
          rootCssVariable.style.setProperty(
            "--color-50",
            headerDetailsGetter.value.meta_header.colors[selectedColor].color1
          );
          rootCssVariable.style.setProperty(
            "--color-100",
            headerDetailsGetter.value.meta_header.colors[selectedColor].color2
          );
          rootCssVariable.style.setProperty(
            "--color-200",
            headerDetailsGetter.value.meta_header.colors[selectedColor].color3
          );
          rootCssVariable.style.setProperty(
            "--color-300",
            headerDetailsGetter.value.meta_header.colors[selectedColor].color4
          );
          rootCssVariable.style.setProperty(
            "--color-400",
            headerDetailsGetter.value.meta_header.colors[selectedColor].color5
          );
          rootCssVariable.style.setProperty(
            "--color-500",
            headerDetailsGetter.value.meta_header.colors[selectedColor].color6
          );
          rootCssVariable.style.setProperty(
            "--color-600",
            headerDetailsGetter.value.meta_header.colors[selectedColor].color7
          );
          rootCssVariable.style.setProperty(
            "--color-700",
            headerDetailsGetter.value.meta_header.colors[selectedColor].color8
          );
          rootCssVariable.style.setProperty(
            "--color-800",
            headerDetailsGetter.value.meta_header.colors[selectedColor].color9
          );
          rootCssVariable.style.setProperty(
            "--color-900",
            headerDetailsGetter.value.meta_header.colors[selectedColor].color10
          );
        }
      }
     }
    watchEffect(() => {
      var urlArr = route.path.split("/");
      if(urlArr[1] != "" && urlArr[1] !='signin' &&  urlArr[1] !='reset_password' &&  urlArr[1] !='terms' && urlArr[1] !='privacy_policy' && urlArr[1] !='register' && urlArr[1] !='lock_screen' && (urlArr[2] == undefined || urlArr[2] !='blocked')){
       headerDisply.value=true;
      }else{
        headerDisply.value=false;
      }
    let headerDetailsa=headerDetailsGetter.value;
      if(!Object.is(headerDetails1,headerDetailsa)){ 
        headerDetails1.value=headerDetailsa; 
       getFavIcon();
      }
    }
    );
    
    return { headerDetailsGetter,headerDetails1,config,headerDisply }
  }
  
  
   
}
</script>

<style lang="css" >
@import "./assets/css/appv1.css";
@import "./assets/css/fonts.css";
@import "./assets/css/jazecustom.css";
@import "./assets/css/stylev1.css";
@import "./assets/css/stylev2.css";

:root {
  --color-50: #ffebee;
  --color-100: #ffcdd2;
  --color-200: #ef9a9a;
  --color-300: #e57373;
  --color-400: #ef5350;
  --color-500: #f44336;
  --color-600: #e53935;
  --color-700: #d32f2f;
  --color-800: #c62828;
  --color-900: #b71c1c;
}
</style>