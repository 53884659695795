<template>
  <div class="jazeMainPanel" style="height: 100%;">
    <div class="jazePanelContent jazePanelContent--scrollable">
      <div class="unifiSettingsContainer">
        <form ref="UserRegistrationForm" id="UserRegistrationForm" class="appForm appForm--cozy is-validation-hidden">
          <div class="unifiSettingsHeader">
            <div class="unifiSettingsHeader__title" v-if="userMandFieldsData != undefined && userMandFieldsData.edition == 'cloud'">Voucher Fields</div>
            <div class="unifiSettingsHeader__title" v-else>User Fields</div>
          </div>
          <fieldset class="appFieldset appFieldset--quiet">
            <div class="unifiSettingsSection">
              <div class="appSubHeading appSubHeading--comfy appSubHeading--standalone toggleShow" @click="viewToggleMenuDatafn('personalInfo')">
                <span>Personal Information</span> &nbsp;<span class="jaze-icon--pointer-right navIcon" :class='(viewToggleMenuData == "personalInfo")? "jaze-icon--pointer-down":"jaze-icon--pointer-right"'></span>
              </div>
              <fieldset class="appFieldset appFieldset--quiet toggleContent" :style='(viewToggleMenuData == "personalInfo")? "":"display:none"'>
                <div class="appRow">
                  <div class="col--sm5 col--md4 col--lg3">
                    <label class="appLabel appLabel--primary"> First Name </label>
                  </div>
                  <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="firstName" :checked="userMandFieldsData != undefined && userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.firstName != undefined && userMandFieldsData.settingValues.firstName == 'Yes' ? true : false">
                      <label>Mandatory</label>
                    </div>
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="hiddenFirstName" :checked="userMandFieldsData != undefined && userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.hiddenFirstName != undefined && userMandFieldsData.settingValues.hiddenFirstName == 'Yes' ? true : false">
                      <label>Hidden</label>
                    </div>
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm5 col--md4 col--lg3">
                    <label class="appLabel appLabel--primary"> Last Name </label>
                  </div>
                  <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="lastName" :checked="userMandFieldsData != undefined && userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.lastName != undefined && userMandFieldsData.settingValues.lastName == 'Yes' ? true : false">
                      <label>Mandatory</label>
                    </div>
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="hiddenLastName" :checked="userMandFieldsData != undefined && userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.hiddenLastName != undefined && userMandFieldsData.settingValues.hiddenLastName == 'Yes' ? true : false">
                      <label>Hidden</label>
                    </div>
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm5 col--md4 col--lg3">
                    <label class="appLabel appLabel--primary"> Gender </label>
                  </div>
                  <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="gender" :checked="userMandFieldsData != undefined && userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.gender != undefined && userMandFieldsData.settingValues.gender == 'Yes' ? true : false">
                      <label>Mandatory</label>
                    </div>
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="hiddenGender" :checked="userMandFieldsData != undefined && userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.hiddenGender != undefined && userMandFieldsData.settingValues.hiddenGender == 'Yes' ? true : false">
                      <label>Hidden</label>
                    </div>
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm5 col--md4 col--lg3">
                    <label class="appLabel appLabel--primary"> Mobile Number </label>
                  </div>
                  <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="phoneNumber" :checked="userMandFieldsData != undefined && userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.phoneNumber != undefined && userMandFieldsData.settingValues.phoneNumber == 'Yes' ? true : false">
                      <label>Mandatory</label>
                    </div>
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="hiddenPhoneNumber" :checked="userMandFieldsData != undefined && userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.hiddenPhoneNumber != undefined && userMandFieldsData.settingValues.hiddenPhoneNumber == 'Yes' ? true : false">
                      <label>Hidden</label>
                    </div>
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm5 col--md4 col--lg3">
                    <label class="appLabel appLabel--primary"> Email ID </label>
                  </div>
                  <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="emailId" :checked="userMandFieldsData != undefined && userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.emailId!=undefined && userMandFieldsData.settingValues.emailId == 'Yes' ? true : false">
                      <label>Mandatory</label>
                    </div>
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="hiddenEmailId" :checked="userMandFieldsData != undefined && userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.hiddenEmailId!=undefined && userMandFieldsData.settingValues.hiddenEmailId == 'Yes' ? true : false">
                      <label>Hidden</label>
                    </div>
                  </div>
                </div>
                <div class="appRow" v-if="userMandFieldsData != undefined && userMandFieldsData.edition=='isp'">
                  <div class="col--sm5 col--md4 col--lg3">
                    <label class="appLabel appLabel--primary"> Alternate Mobile Number </label>
                  </div>
                  <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="altPhoneNumber" :checked="userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.altPhoneNumber != undefined && userMandFieldsData.settingValues.altPhoneNumber == 'Yes' ? true : false">
                      <label>Mandatory</label>
                    </div>
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="hiddenAltPhoneNumber" :checked="userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.hiddenAltPhoneNumber != undefined && userMandFieldsData.settingValues.hiddenAltPhoneNumber == 'Yes' ? true : false">
                      <label>Hidden</label>
                    </div>
                  </div>
                </div>
                <div class="appRow" v-if="userMandFieldsData != undefined && userMandFieldsData.edition=='isp'">
                  <div class="col--sm5 col--md4 col--lg3">
                    <label class="appLabel appLabel--primary"> Alternate Email ID </label>
                  </div>
                  <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="altEmailId" :checked="userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.altEmailId != undefined && userMandFieldsData.settingValues.altEmailId == 'Yes' ? true : false">
                      <label>Mandatory</label>
                    </div>
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="hiddenAltEmailId" :checked="userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.hiddenAltEmailId != undefined && userMandFieldsData.settingValues.hiddenAltEmailId == 'Yes' ? true : false">
                      <label>Hidden</label>
                    </div>
                  </div>
                </div>
                <div class="appRow" v-if="userMandFieldsData != undefined && userMandFieldsData.edition=='isp'">
                  <div class="col--sm5 col--md4 col--lg3">
                    <label class="appLabel appLabel--primary"> Billing Address Line 1 </label>
                  </div>
                  <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="address_line1" :checked="userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.address_line1 != undefined && userMandFieldsData.settingValues.address_line1 == 'Yes' ? true : false">
                      <label>Mandatory</label>
                    </div>
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="hiddenaddress_line1" :checked="userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.hiddenaddress_line1 != undefined && userMandFieldsData.settingValues.hiddenaddress_line1 == 'Yes' ? true : false">
                      <label>Hidden</label>
                    </div>
                  </div>
                </div>
                <div class="appRow" v-if="userMandFieldsData != undefined && userMandFieldsData.edition=='isp'">
                  <div class="col--sm5 col--md4 col--lg3">
                    <label class="appLabel appLabel--primary"> Billing Address Line 2 </label>
                  </div>
                  <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="address_line2" :checked="userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.address_line2 != undefined && userMandFieldsData.settingValues.address_line2 == 'Yes' ? true : false">
                      <label>Mandatory</label>
                    </div>
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="hiddenaddress_line2" :checked="userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.hiddenaddress_line2 != undefined && userMandFieldsData.settingValues.hiddenaddress_line2 == 'Yes' ? true : false">
                      <label>Hidden</label>
                    </div>
                  </div>
                </div>
                <div class="appRow" v-if="userMandFieldsData != undefined && userMandFieldsData.edition=='isp'">
                  <div class="col--sm5 col--md4 col--lg3">
                    <label class="appLabel appLabel--primary"> City </label>
                  </div>
                  <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="address_city" :checked="userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.address_city != undefined && userMandFieldsData.settingValues.address_city == 'Yes' ? true : false">
                      <label>Mandatory</label>
                    </div>
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="hiddenaddress_city" :checked="userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.hiddenaddress_city != undefined && userMandFieldsData.settingValues.hiddenaddress_city == 'Yes' ? true : false">
                      <label>Hidden</label>
                    </div>
                  </div>
                </div>
                <div class="appRow" v-if="userMandFieldsData != undefined && userMandFieldsData.edition=='isp'">
                  <div class="col--sm5 col--md4 col--lg3">
                    <label class="appLabel appLabel--primary"> Pincode </label>
                  </div>
                  <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="address_pin" :checked="userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.address_pin != undefined && userMandFieldsData.settingValues.address_pin == 'Yes' ? true : false">
                      <label>Mandatory</label> 
                    </div>
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="hiddenaddress_pin" :checked="userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.hiddenaddress_pin != undefined && userMandFieldsData.settingValues.hiddenaddress_pin == 'Yes' ? true : false">
                      <label>Hidden</label>
                    </div>
                  </div>
                </div>
                <div class="appRow" v-if="userMandFieldsData != undefined && userMandFieldsData.edition=='isp'">
                  <div class="col--sm5 col--md4 col--lg3">
                    <label class="appLabel appLabel--primary"> State </label>
                  </div>
                  <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="address_state" :checked="userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.address_state != undefined && userMandFieldsData.settingValues.address_state == 'Yes' ? true : false">
                      <label>Mandatory</label>
                    </div>
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="hiddenaddress_state" :checked="userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.hiddenaddress_state != undefined && userMandFieldsData.settingValues.hiddenaddress_state == 'Yes' ? true : false">
                      <label>Hidden</label>
                    </div>
                  </div>
                </div>
                <div class="appRow" v-if="userMandFieldsData != undefined && userMandFieldsData.edition=='isp'">
                  <div class="col--sm5 col--md4 col--lg3">
                    <label class="appLabel appLabel--primary"> User state accept only known fields (applicable for only India) </label>
                  </div>
                  <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="knownUserState" :checked="userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.knownUserState != undefined && userMandFieldsData.settingValues.knownUserState == 'Yes' ? true : false">
                    </div>
                  </div>
                </div>
                <div class="appRow" v-if="userMandFieldsData != undefined && userMandFieldsData.edition=='isp'">
                  <div class="col--sm5 col--md4 col--lg3">
                    <label class="appLabel appLabel--primary"> Permanent Address </label>
                  </div>
                  <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="address" :checked="userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.address != undefined && userMandFieldsData.settingValues.address == 'Yes' ? true : false">
                      <label>Mandatory</label>
                    </div>
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="hiddenaddress" :checked="userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.hiddenaddress != undefined && userMandFieldsData.settingValues.hiddenaddress == 'Yes' ? true : false">
                      <label>Hidden</label>
                    </div>
                  </div>
                </div>
                <div class="appRow" v-if="userMandFieldsData != undefined && userMandFieldsData.edition=='isp'">
                  <div class="col--sm5 col--md4 col--lg3">
                    <label class="appLabel appLabel--primary"> Country </label>
                  </div>
                  <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="billing_country" :checked="userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.billing_country != undefined && userMandFieldsData.settingValues.billing_country == 'Yes' ? true : false">
                      <label>Mandatory</label>
                    </div>
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="hiddenbilling_country" :checked="userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.hiddenbilling_country != undefined && userMandFieldsData.settingValues.hiddenbilling_country == 'Yes' ? true : false">
                      <label>Hidden</label>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
            <div class="unifiSettingsSection" v-if="userMandFieldsData != undefined && userMandFieldsData.edition=='isp'">
              <div class="appSubHeading appSubHeading--comfy appSubHeading--standalone toggleShow" @click="viewToggleMenuDatafn('installetionAddress')">
                <span>Installation Address</span> &nbsp;<span class="jaze-icon--pointer-right navIcon" :class='(viewToggleMenuData == "installetionAddress")? "jaze-icon--pointer-down":"jaze-icon--pointer-right"'></span>
              </div>
              <fieldset class="appFieldset appFieldset--quiet toggleContent" :style='(viewToggleMenuData == "installetionAddress")? "":"display:none"'>
                <div class="appRow">
                  <div class="col--sm5 col--md4 col--lg3">
                    <label class="appLabel appLabel--primary"> Address Line 1 </label>
                  </div>
                  <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="installation_address_line1" :checked="userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.installation_address_line1 != undefined && userMandFieldsData.settingValues.installation_address_line1 == 'Yes' ? true : false">
                      <label>Mandatory</label>
                    </div>
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="hiddeninstallation_address_line1" :checked="userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.hiddeninstallation_address_line1 != undefined && userMandFieldsData.settingValues.hiddeninstallation_address_line1 == 'Yes' ? true : false">
                      <label>Hidden</label>
                    </div>
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm5 col--md4 col--lg3">
                    <label class="appLabel appLabel--primary"> Address Line 2 </label>
                  </div>
                  <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="installation_address_line2" :checked="userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.installation_address_line2 != undefined && userMandFieldsData.settingValues.installation_address_line2 == 'Yes' ? true : false">
                      <label>Mandatory</label>
                    </div>
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="hiddeninstallation_address_line2" :checked="userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.hiddeninstallation_address_line2 != undefined && userMandFieldsData.settingValues.hiddeninstallation_address_line2 == 'Yes' ? true : false">
                      <label>Hidden</label>
                    </div>
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm5 col--md4 col--lg3">
                    <label class="appLabel appLabel--primary"> City </label>
                  </div>
                  <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="installation_address_city" :checked="userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.installation_address_city != undefined && userMandFieldsData.settingValues.installation_address_city == 'Yes' ? true : false">
                      <label>Mandatory</label>
                    </div>
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="hiddeninstallation_address_city" :checked="userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.hiddeninstallation_address_city != undefined && userMandFieldsData.settingValues.hiddeninstallation_address_city == 'Yes' ? true : false">
                      <label>Hidden</label>
                    </div>
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm5 col--md4 col--lg3">
                    <label class="appLabel appLabel--primary"> Pincode </label>
                  </div>
                  <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="installation_address_pin" :checked="userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.installation_address_pin != undefined && userMandFieldsData.settingValues.installation_address_pin == 'Yes' ? true : false">
                      <label>Mandatory</label>
                    </div>
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="hiddeninstallation_address_pin" :checked="userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.hiddeninstallation_address_pin != undefined && userMandFieldsData.settingValues.hiddeninstallation_address_pin == 'Yes' ? true : false">
                      <label>Hidden</label>
                    </div>
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm5 col--md4 col--lg3">
                    <label class="appLabel appLabel--primary"> State </label>
                  </div>
                  <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="installation_address_state" :checked="userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.installation_address_state != undefined && userMandFieldsData.settingValues.installation_address_state == 'Yes' ? true : false">
                      <label>Mandatory</label>
                    </div>
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="hiddeninstallation_address_state" :checked="userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.hiddeninstallation_address_state != undefined && userMandFieldsData.settingValues.hiddeninstallation_address_state == 'Yes' ? true : false">
                      <label>Hidden</label>
                    </div>
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm5 col--md4 col--lg3">
                    <label class="appLabel appLabel--primary"> Country </label>
                  </div>
                  <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="installation_address_country" :checked="userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.installation_address_country != undefined && userMandFieldsData.settingValues.installation_address_country == 'Yes' ? true : false">
                      <label>Mandatory</label>
                    </div>
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="hiddeninstallation_address_country" :checked="userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.hiddeninstallation_address_country != undefined && userMandFieldsData.settingValues.hiddeninstallation_address_country == 'Yes' ? true : false">
                      <label>Hidden</label>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
            <div class="unifiSettingsSection" v-if="userMandFieldsData != undefined && userMandFieldsData.edition=='isp'">
              <div class="appSubHeading appSubHeading--comfy appSubHeading--standalone toggleShow" @click="viewToggleMenuDatafn('billingInfo')">
                <span>Billing Information</span> &nbsp;<span class="jaze-icon--pointer-right navIcon" :class='(viewToggleMenuData == "billingInfo")? "jaze-icon--pointer-down":"jaze-icon--pointer-right"'></span>
              </div>
              <fieldset class="appFieldset appFieldset--quiet toggleContent" :style='(viewToggleMenuData == "billingInfo")? "":"display:none"'>
                <div class="appRow">
                  <div class="col--sm5 col--md4 col--lg3">
                    <label class="appLabel appLabel--primary"> Create Billing </label>
                  </div>
                  <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="createBilling" :checked="userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.createBilling != undefined && userMandFieldsData.settingValues.createBilling == 'Yes' ? true : false">
                      <label>Mandatory</label>
                    </div>
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="hiddenCreateBilling" :checked="userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.hiddenCreateBilling != undefined && userMandFieldsData.settingValues.hiddenCreateBilling == 'Yes' ? true : false">
                      <label>Hidden</label>
                    </div>
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm5 col--md4 col--lg3">
                    <label class="appLabel appLabel--primary"> Create IPTV Billing </label>
                  </div>
                  <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="createIPTVBilling" :checked="userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.createIPTVBilling != undefined && userMandFieldsData.settingValues.createIPTVBilling == 'Yes' ? true : false">
                      <label>Mandatory</label>
                    </div>
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="hiddenCreateIPTVBilling" :checked="userMandFieldsData.settingValues != undefined && (userMandFieldsData.settingValues.hiddenCreateIPTVBilling == undefined || userMandFieldsData.settingValues.hiddenCreateIPTVBilling == 'Yes') ? true : false">
                      <label>Hidden</label>
                    </div>
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm5 col--md4 col--lg3">
                    <label class="appLabel appLabel--primary"> Create IP Phone Billing </label>
                  </div>
                  <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="createIPPhoneBilling" :checked="userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.createIPPhoneBilling != undefined && userMandFieldsData.settingValues.createIPPhoneBilling == 'Yes' ? true : false">
                      <label>Mandatory</label>
                    </div>
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="hiddenCreateIPPhoneBilling" :checked="userMandFieldsData.settingValues != undefined && (userMandFieldsData.settingValues.hiddenCreateIPPhoneBilling == undefined || userMandFieldsData.settingValues.hiddenCreateIPPhoneBilling == 'Yes') ? true : false">
                      <label>Hidden</label>
                    </div>
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm5 col--md4 col--lg3">
                    <label class="appLabel appLabel--primary"> Create OTT Billing </label>
                  </div>
                  <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="createOTTBilling" :checked="userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.createOTTBilling != undefined && userMandFieldsData.settingValues.createOTTBilling == 'Yes' ? true : false">
                      <label>Mandatory</label>
                    </div>
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="hiddenCreateOTTBilling" :checked="userMandFieldsData.settingValues != undefined && (userMandFieldsData.settingValues.hiddenCreateOTTBilling == undefined || userMandFieldsData.settingValues.hiddenCreateOTTBilling == 'Yes') ? true : false">
                      <label>Hidden</label>
                    </div>
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm5 col--md4 col--lg3">
                    <label class="appLabel appLabel--primary"> Installation Charge </label>
                  </div>
                  <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="installationCharge" :checked="userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.installationCharge != undefined && userMandFieldsData.settingValues.installationCharge == 'Yes' ? true : false">
                      <label>Mandatory</label>
                    </div>
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="hiddenInstallationCharge" :checked="userMandFieldsData.settingValues != undefined && (userMandFieldsData.settingValues.hiddenInstallationCharge == undefined || userMandFieldsData.settingValues.hiddenInstallationCharge == 'Yes') ? true : false">
                      <label>Hidden</label>
                    </div>
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm5 col--md4 col--lg3">
                    <label class="appLabel appLabel--primary"> Deposit Amount Paid </label>
                  </div>
                  <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="depositAmount" :checked="userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.depositAmount != undefined && userMandFieldsData.settingValues.depositAmount == 'Yes' ? true : false">
                      <label>Mandatory</label>
                    </div>
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="hiddenDepositAmount" :checked="userMandFieldsData.settingValues != undefined && (userMandFieldsData.settingValues.hiddenDepositAmount == undefined || userMandFieldsData.settingValues.hiddenDepositAmount == 'Yes') ? true : false">
                      <label>Hidden</label>
                    </div>
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm5 col--md4 col--lg3">
                    <label class="appLabel appLabel--primary"> Router Charge </label>
                  </div>
                  <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="routerCharge" :checked="userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.routerCharge != undefined && userMandFieldsData.settingValues.routerCharge == 'Yes' ? true : false">
                      <label>Mandatory</label>
                    </div>
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="hiddenRouterCharge" :checked="userMandFieldsData.settingValues != undefined && (userMandFieldsData.settingValues.hiddenRouterCharge == undefined || userMandFieldsData.settingValues.hiddenRouterCharge == 'Yes') ? true : false">
                      <label>Hidden</label>
                    </div>
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm5 col--md4 col--lg3">
                    <label class="appLabel appLabel--primary"> Make Payment For Invoice </label>
                  </div>
                  <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="makePaymentInvoice" :checked="userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.makePaymentInvoice != undefined && userMandFieldsData.settingValues.makePaymentInvoice == 'Yes' ? true : false">
                      <label>Mandatory</label>
                    </div>
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="hiddenMakePaymentInvoice" :checked="userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.hiddenMakePaymentInvoice != undefined && userMandFieldsData.settingValues.hiddenMakePaymentInvoice == 'Yes' ? true : false">
                      <label>Hidden</label>
                    </div>
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm5 col--md4 col--lg3">
                    <label class="appLabel appLabel--primary"> Choose what to do after bill due date </label>
                  </div>
                  <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="afterAction" :checked="userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.afterAction != undefined && userMandFieldsData.settingValues.afterAction == 'Yes' ? true : false">
                      <label>Mandatory</label>
                    </div>
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="hiddenAfterAction" :checked="userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.hiddenAfterAction != undefined && userMandFieldsData.settingValues.hiddenAfterAction == 'Yes' ? true : false">
                      <label>Hidden</label>
                    </div>
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm5 col--md4 col--lg3">
                    <label class="appLabel appLabel--primary"> Exclude from reminder notifications </label>
                  </div>
                  <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="excludeFromNotification" :checked="userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.excludeFromNotification != undefined && userMandFieldsData.settingValues.excludeFromNotification == 'Yes' ? true : false">
                      <label>Mandatory</label>
                    </div>
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="hiddenExcludeFromNotification" :checked="userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.hiddenExcludeFromNotification != undefined && userMandFieldsData.settingValues.hiddenExcludeFromNotification == 'Yes' ? true : false">
                      <label>Hidden</label>
                    </div>
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm5 col--md4 col--lg3">
                    <label class="appLabel appLabel--primary"> GST Number </label>
                  </div>
                  <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="gstNumber" :checked="userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.gstNumber != undefined && userMandFieldsData.settingValues.gstNumber == 'Yes' ? true : false">
                      <label>Mandatory</label>
                    </div>
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="hiddenGstNumber" :checked="userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.hiddenGstNumber != undefined && userMandFieldsData.settingValues.hiddenGstNumber == 'Yes' ? true : false">
                      <label>Hidden</label>
                    </div>
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm5 col--md4 col--lg3">
                    <label class="appLabel appLabel--primary"> GST Applicable From </label>
                  </div>
                  <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="gstApplicableFrom" :checked="userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.gstApplicableFrom != undefined && userMandFieldsData.settingValues.gstApplicableFrom == 'Yes' ? true : false">
                      <label>Mandatory</label>
                    </div>
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="hiddenGstApplicableFrom" :checked="userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.hiddenGstApplicableFrom != undefined && userMandFieldsData.settingValues.hiddenGstApplicableFrom == 'Yes' ? true : false">
                      <label>Hidden</label>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
            <div class="unifiSettingsSection">
              <div class="appSubHeading appSubHeading--comfy appSubHeading--standalone toggleShow" @click="viewToggleMenuDatafn('otherInfo')">
                <span>Other Information</span> &nbsp;<span class="jaze-icon--pointer-right navIcon" :class='(viewToggleMenuData == "otherInfo")? "jaze-icon--pointer-down":"jaze-icon--pointer-right"'></span>
              </div>
              <fieldset class="appFieldset appFieldset--quiet toggleContent" :style='(viewToggleMenuData == "otherInfo")? "":"display:none"'>
                <div class="appRow" v-if="userMandFieldsData != undefined && userMandFieldsData.edition=='isp'">
                  <div class="col--sm5 col--md4 col--lg3">
                    <label class="appLabel appLabel--primary"> User Type </label>
                  </div>
                  <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="userType" :checked="userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.userType != undefined && userMandFieldsData.settingValues.userType == 'Yes' ? true : false">
                      <label>Mandatory</label>
                    </div>
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="hiddenUserType" :checked="userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.hiddenUserType != undefined && userMandFieldsData.settingValues.hiddenUserType == 'Yes' ? true : false">
                      <label>Hidden</label>
                    </div>
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm5 col--md4 col--lg3">
                    <label class="appLabel appLabel--primary" v-if="userMandFieldsData != undefined && userMandFieldsData.edition=='isp'"> Circuit ID </label>
                    <label class="appLabel appLabel--primary" v-else> Serial Number </label>
                  </div>
                  <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="circuitId" :checked="userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.circuitId != undefined && userMandFieldsData.settingValues.circuitId == 'Yes' ? true : false">
                      <label>Mandatory</label>
                    </div>
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="hiddenCircuitId" :checked="userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.hiddenCircuitId != undefined && userMandFieldsData.settingValues.hiddenCircuitId == 'Yes' ? true : false">
                      <label>Hidden</label>
                    </div>
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm5 col--md4 col--lg3">
                    <label class="appLabel appLabel--primary"> Referral Code </label>
                  </div>
                  <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="referralCode" :checked="userMandFieldsData != undefined && userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.referralCode != undefined && userMandFieldsData.settingValues.referralCode == 'Yes' ? true : false">
                      <label>Mandatory</label>
                    </div>
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="hiddenReferralCode" :checked="userMandFieldsData != undefined && userMandFieldsData.settingValues != undefined && (userMandFieldsData.settingValues.hiddenReferralCode == undefined || userMandFieldsData.settingValues.hiddenReferralCode == 'Yes') ? true : false">
                      <label>Hidden</label>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
            <div class="unifiSettingsSection">
              <div class="appSubHeading appSubHeading--comfy appSubHeading--standalone toggleShow" @click="viewToggleMenuDatafn('locationDetail')">
                <span>Location Details</span> &nbsp;<span class="jaze-icon--pointer-right navIcon" :class='(viewToggleMenuData == "locationDetail")? "jaze-icon--pointer-down":"jaze-icon--pointer-right"'></span>
              </div>
              <fieldset class="appFieldset appFieldset--quiet toggleContent" :style='(viewToggleMenuData == "locationDetail")? "":"display:none"'>
                <div class="appRow">
                  <div class="col--sm5 col--md4 col--lg3">
                    <label class="appLabel appLabel--primary"> Area </label>
                  </div>
                  <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="collection_area_import" :checked="userMandFieldsData != undefined && userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.collection_area_import != undefined && userMandFieldsData.settingValues.collection_area_import == 'Yes' ? true : false">
                      <label>Mandatory</label>
                    </div>
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="hiddencollection_area_import" :checked="userMandFieldsData != undefined && userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.hiddencollection_area_import != undefined && userMandFieldsData.settingValues.hiddencollection_area_import == 'Yes' ? true : false">
                      <label>Hidden</label>
                    </div>
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm5 col--md4 col--lg3">
                    <label class="appLabel appLabel--primary"> Street </label>
                  </div>
                  <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="collection_street_import" :checked="userMandFieldsData != undefined && userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.collection_street_import != undefined && userMandFieldsData.settingValues.collection_street_import == 'Yes' ? true : false">
                      <label>Mandatory</label>
                    </div>
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="hiddencollection_street_import" :checked="userMandFieldsData != undefined && userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.hiddencollection_street_import != undefined && userMandFieldsData.settingValues.hiddencollection_street_import == 'Yes' ? true : false">
                      <label>Hidden</label>
                    </div>
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm5 col--md4 col--lg3">
                    <label class="appLabel appLabel--primary"> Building </label>
                  </div>
                  <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="collection_block_import" :checked="userMandFieldsData != undefined && userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.collection_block_import != undefined && userMandFieldsData.settingValues.collection_block_import == 'Yes' ? true : false">
                      <label>Mandatory</label>
                    </div>
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="hiddencollection_block_import" :checked="userMandFieldsData != undefined && userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.hiddencollection_block_import != undefined && userMandFieldsData.settingValues.hiddencollection_block_import == 'Yes' ? true : false">
                      <label>Hidden</label>
                    </div>
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm5 col--md4 col--lg3">
                    <label class="appLabel appLabel--primary"> Latitude </label>
                  </div>
                  <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="collection_latitude_import" :checked="userMandFieldsData != undefined && userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.collection_latitude_import != undefined && userMandFieldsData.settingValues.collection_latitude_import == 'Yes' ? true : false">
                      <label>Mandatory</label>
                    </div>
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="hiddencollection_latitude_import" :checked="userMandFieldsData != undefined && userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.hiddencollection_latitude_import != undefined && userMandFieldsData.settingValues.hiddencollection_latitude_import == 'Yes' ? true : false">
                      <label>Hidden</label>
                    </div>
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm5 col--md4 col--lg3">
                    <label class="appLabel appLabel--primary"> Longitude </label>
                  </div>
                  <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="collection_longitude_import" :checked="userMandFieldsData != undefined && userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.collection_longitude_import != undefined && userMandFieldsData.settingValues.collection_longitude_import == 'Yes' ? true : false">
                      <label>Mandatory</label>
                    </div>
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="hiddencollection_longitude_import" :checked="userMandFieldsData != undefined && userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.hiddencollection_longitude_import != undefined && userMandFieldsData.settingValues.hiddencollection_longitude_import == 'Yes' ? true : false">
                      <label>Hidden</label>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
            <div class="unifiSettingsSection">
              <div class="appSubHeading appSubHeading--comfy appSubHeading--standalone toggleShow" @click="viewToggleMenuDatafn('proof')">
                <span>Proof</span> &nbsp;<span class="jaze-icon--pointer-right navIcon" :class='(viewToggleMenuData == "proof")? "jaze-icon--pointer-down":"jaze-icon--pointer-right"'></span>
              </div>
              <fieldset class="appFieldset appFieldset--quiet toggleContent" :style='(viewToggleMenuData == "proof")? "":"display:none"'>
                <div class="appRow">
                  <div class="col--sm5 col--md4 col--lg3">
                    <label class="appLabel appLabel--primary"> ID Proof </label>
                  </div>
                  <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="id_proof" :checked="userMandFieldsData != undefined && userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.id_proof != undefined && userMandFieldsData.settingValues.id_proof == 'Yes' ? true : false">
                      <label>Mandatory</label>
                    </div>
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="hiddenid_proof" :checked="userMandFieldsData != undefined && userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.hiddenid_proof != undefined && userMandFieldsData.settingValues.hiddenid_proof == 'Yes' ? true : false">
                      <label>Hidden</label>
                    </div>
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm5 col--md4 col--lg3">
                    <label class="appLabel appLabel--primary"> Upload ID Proof </label>
                  </div>
                  <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="submittedIdProof" :checked="userMandFieldsData != undefined && userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.submittedIdProof != undefined && userMandFieldsData.settingValues.submittedIdProof == 'Yes' ? true : false">
                      <label>Mandatory</label>
                    </div>
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="hiddensubmittedIdProof" :checked="userMandFieldsData != undefined && userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.hiddensubmittedIdProof != undefined && userMandFieldsData.settingValues.hiddensubmittedIdProof == 'Yes' ? true : false">
                      <label>Hidden</label>
                    </div>
                  </div>
                </div>
                <div class="appRow" v-if="userMandFieldsData != undefined && userMandFieldsData.edition=='isp'">
                  <div class="col--sm5 col--md4 col--lg3">
                    <label class="appLabel appLabel--primary"> Installation Time </label>
                  </div>
                  <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="installation_time" :checked="userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.installation_time != undefined && userMandFieldsData.settingValues.installation_time == 'Yes' ? true : false">
                      <label>Mandatory</label>
                    </div>
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="hiddeninstallation_time" :checked="userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.hiddeninstallation_time != undefined && userMandFieldsData.settingValues.hiddeninstallation_time == 'Yes' ? true : false">
                      <label>Hidden</label>
                    </div>
                  </div>
                </div>
                <div class="appRow" v-if="userMandFieldsData != undefined && userMandFieldsData.edition=='isp'">
                  <div class="col--sm5 col--md4 col--lg3">
                    <label class="appLabel appLabel--primary"> Upload Address Proof </label>
                  </div>
                  <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="submittedproof" :checked="userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.submittedproof != undefined && userMandFieldsData.settingValues.submittedproof == 'Yes' ? true : false">
                      <label>Mandatory</label>
                    </div>
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="hiddensubmittedproof" :checked="userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.hiddensubmittedproof != undefined && userMandFieldsData.settingValues.hiddensubmittedproof == 'Yes' ? true : false">
                      <label>Hidden</label>
                    </div>
                  </div>
                </div>
                <div class="appRow" v-if="userMandFieldsData != undefined && userMandFieldsData.edition=='isp'">
                  <div class="col--sm5 col--md4 col--lg3">
                    <label class="appLabel appLabel--primary"> Upload Customer Acquisition Form </label>
                  </div>
                  <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="submittedform" :checked="userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.submittedform != undefined && userMandFieldsData.settingValues.submittedform == 'Yes' ? true : false">
                      <label>Mandatory</label>
                    </div>
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="hiddensubmittedform" :checked="userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.hiddensubmittedform != undefined && userMandFieldsData.settingValues.hiddensubmittedform == 'Yes' ? true : false">
                      <label>Hidden</label>
                    </div>
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm5 col--md4 col--lg3">
                    <label class="appLabel appLabel--primary"> CAF Number </label>
                  </div>
                  <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="caf_num" :checked="userMandFieldsData != undefined && userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.caf_num != undefined && userMandFieldsData.settingValues.caf_num == 'Yes' ? true : false">
                      <label>Mandatory</label>
                    </div>
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="hiddencaf_num" :checked="userMandFieldsData != undefined && userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.hiddencaf_num != undefined && userMandFieldsData.settingValues.hiddencaf_num == 'Yes' ? true : false">
                      <label>Hidden</label>
                    </div>
                  </div>
                </div>
                <div class="appRow" v-if="userMandFieldsData != undefined && userMandFieldsData.edition=='isp'">
                  <div class="col--sm5 col--md4 col--lg3">
                    <label class="appLabel appLabel--primary"> Upload Installation Report </label>
                  </div>
                  <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="submittedReport" :checked="userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.submittedReport != undefined && userMandFieldsData.settingValues.submittedReport == 'Yes' ? true : false">
                      <label>Mandatory</label>
                    </div>
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="hiddensubmittedReport" :checked="userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.hiddensubmittedReport != undefined && userMandFieldsData.settingValues.hiddensubmittedReport == 'Yes' ? true : false">
                      <label>Hidden</label>
                    </div>
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm5 col--md4 col--lg3">
                    <label class="appLabel appLabel--primary"> Upload Profile Photo </label>
                  </div>
                  <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="photo_proof" :checked="userMandFieldsData != undefined && userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.photo_proof != undefined && userMandFieldsData.settingValues.photo_proof == 'Yes' ? true : false">
                      <label>Mandatory</label>
                    </div>
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="hiddenphoto_proof" :checked="userMandFieldsData != undefined && userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.hiddenphoto_proof != undefined && userMandFieldsData.settingValues.hiddenphoto_proof == 'Yes' ? true : false">
                      <label>Hidden</label>
                    </div>
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm5 col--md4 col--lg3">
                    <label class="appLabel appLabel--primary"> Upload Signature Proof </label>
                  </div>
                  <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="sign_proof" :checked="userMandFieldsData != undefined && userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.sign_proof != undefined && userMandFieldsData.settingValues.sign_proof == 'Yes' ? true : false">
                      <label>Mandatory</label>
                    </div>
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="hiddensign_proof" :checked="userMandFieldsData != undefined && userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.hiddensign_proof != undefined && userMandFieldsData.settingValues.hiddensign_proof == 'Yes' ? true : false">
                      <label>Hidden</label>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
            <div class="unifiSettingsSection">
              <div class="appSubHeading appSubHeading--comfy appSubHeading--standalone toggleShow" @click="viewToggleMenuDatafn('networkInfo')">
                <span>Network Information</span> &nbsp;<span class="jaze-icon--pointer-right navIcon" :class='(viewToggleMenuData == "networkInfo")? "jaze-icon--pointer-down":"jaze-icon--pointer-right"'></span>
              </div>
              <fieldset class="appFieldset appFieldset--quiet toggleContent" :style='(viewToggleMenuData == "networkInfo")? "":"display:none"'>
                <div class="appRow">
                  <div class="col--sm5 col--md4 col--lg3">
                    <label class="appLabel appLabel--primary"> Static IP allocation/MAC whitelist </label>
                  </div>
                  <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="staticIpAddress" :checked="userMandFieldsData != undefined && userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.staticIpAddress != undefined && userMandFieldsData.settingValues.staticIpAddress == 'Yes' ? true : false">
                      <label>Mandatory</label>
                    </div>
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="hiddenStaticIpAddress" :checked="userMandFieldsData != undefined && userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.hiddenStaticIpAddress != undefined && userMandFieldsData.settingValues.hiddenStaticIpAddress == 'Yes' ? true : false">
                      <label>Hidden</label>
                    </div>
                  </div>
                </div>
                <div class="appRow" v-if="userMandFieldsData != undefined && userMandFieldsData.edition=='isp'">
                  <div class="col--sm5 col--md4 col--lg3">
                    <label class="appLabel appLabel--primary"> IP Pool </label>
                  </div>
                  <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="ipPoolName" :checked="userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.ipPoolName != undefined && userMandFieldsData.settingValues.ipPoolName == 'Yes' ? true : false">
                      <label>Mandatory</label>
                    </div>
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="hiddenIpPoolName" :checked="userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.hiddenIpPoolName != undefined && userMandFieldsData.settingValues.hiddenIpPoolName == 'Yes' ? true : false">
                      <label>Hidden</label>
                    </div>
                  </div>
                </div>
                <div class="appRow" v-if="userMandFieldsData != undefined && userMandFieldsData.edition=='isp'">
                  <div class="col--sm5 col--md4 col--lg3">
                    <label class="appLabel appLabel--primary"> Enable IP Auth </label>
                  </div>
                  <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="enableUserIpAuth" :checked="userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.enableUserIpAuth != undefined && userMandFieldsData.settingValues.enableUserIpAuth == 'Yes' ? true : false">
                      <label>Mandatory</label>
                    </div>
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="hiddenEnableUserIpAuth" :checked="userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.hiddenEnableUserIpAuth != undefined && userMandFieldsData.settingValues.hiddenEnableUserIpAuth == 'Yes' ? true : false">
                      <label>Hidden</label>
                    </div>
                  </div>
                </div>
                <div class="appRow" v-if="userMandFieldsData != undefined && userMandFieldsData.edition=='isp'">
                  <div class="col--sm5 col--md4 col--lg3">
                    <label class="appLabel appLabel--primary"> Disable IP Auth </label>
                  </div>
                  <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="disableUserIpAuth" :checked="userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.disableUserIpAuth != undefined && userMandFieldsData.settingValues.disableUserIpAuth == 'Yes' ? true : false">
                      <label>Mandatory</label>
                    </div>
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="hiddenDisableUserIpAuth" :checked="userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.hiddenDisableUserIpAuth != undefined && userMandFieldsData.settingValues.hiddenDisableUserIpAuth == 'Yes' ? true : false">
                      <label>Hidden</label>
                    </div>
                  </div>
                </div>
                <div class="appRow" v-if="userMandFieldsData != undefined && userMandFieldsData.edition=='isp'">
                  <div class="col--sm5 col--md4 col--lg3">
                    <label class="appLabel appLabel--primary"> Disable MAC Auth </label>
                  </div>
                  <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="disableUserMacAuth" :checked="userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.disableUserMacAuth != undefined && userMandFieldsData.settingValues.disableUserMacAuth == 'Yes' ? true : false">
                      <label>Mandatory</label>
                    </div>
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="hiddenDisableUserMacAuth" :checked="userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.hiddenDisableUserMacAuth != undefined && userMandFieldsData.settingValues.hiddenDisableUserMacAuth == 'Yes' ? true : false">
                      <label>Hidden</label>
                    </div>
                  </div>
                </div>
                <div class="appRow" v-if="userMandFieldsData != undefined && userMandFieldsData.edition=='isp'">
                  <div class="col--sm5 col--md4 col--lg3">
                    <label class="appLabel appLabel--primary"> Disable PPPoE Auth </label>
                  </div>
                  <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="disableUserPppoeAuth" :checked="userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.disableUserPppoeAuth != undefined && userMandFieldsData.settingValues.disableUserPppoeAuth == 'Yes' ? true : false">
                      <label>Mandatory</label>
                    </div>
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="hiddenDisableUserPppoeAuth" :checked="userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.hiddenDisableUserPppoeAuth != undefined && userMandFieldsData.settingValues.hiddenDisableUserPppoeAuth == 'Yes' ? true : false">
                      <label>Hidden</label>
                    </div>
                  </div>
                </div>
                <div class="appRow" v-if="userMandFieldsData != undefined && userMandFieldsData.edition=='isp'">
                  <div class="col--sm5 col--md4 col--lg3">
                    <label class="appLabel appLabel--primary"> Disable Hotspot Auth </label>
                  </div>
                  <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="disableUserHotspotAuth" :checked="userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.disableUserHotspotAuth != undefined && userMandFieldsData.settingValues.disableUserHotspotAuth == 'Yes' ? true : false">
                      <label>Mandatory</label>
                    </div>
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="hiddenDisableUserHotspotAuth" :checked="userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.hiddenDisableUserHotspotAuth != undefined && userMandFieldsData.settingValues.hiddenDisableUserHotspotAuth == 'Yes' ? true : false">
                      <label>Hidden</label>
                    </div>
                  </div>
                </div>
                <div class="appRow" v-if="userMandFieldsData != undefined && userMandFieldsData.edition=='isp'">
                  <div class="col--sm5 col--md4 col--lg3">
                    <label class="appLabel appLabel--primary"> Create Static Queue in Router </label>
                  </div>
                  <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="createStaticQueue" :checked="userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.createStaticQueue != undefined && userMandFieldsData.settingValues.createStaticQueue == 'Yes' ? true : false">
                      <label>Mandatory</label>
                    </div>
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="hiddenCreateStaticQueue" :checked="userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.hiddenCreateStaticQueue != undefined && userMandFieldsData.settingValues.hiddenCreateStaticQueue == 'Yes' ? true : false">
                      <label>Hidden</label>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
            <div class="unifiSettingsSection">
              <div class="appSubHeading appSubHeading--comfy appSubHeading--standalone toggleShow" @click="viewToggleMenuDatafn('inventory')">
                <span>Inventory</span> &nbsp;<span class="jaze-icon--pointer-right navIcon" :class='(viewToggleMenuData == "inventory")? "jaze-icon--pointer-down":"jaze-icon--pointer-right"'></span>
              </div>
              <fieldset class="appFieldset appFieldset--quiet toggleContent" :style='(viewToggleMenuData == "inventory")? "":"display:none"'>
                <div class="appRow">
                  <div class="col--sm5 col--md4 col--lg3">
                    <label class="appLabel appLabel--primary"> Add Inventory items to the user </label>
                  </div>
                  <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="addInventoryItems" :checked="userMandFieldsData != undefined && userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.addInventoryItems != undefined && userMandFieldsData.settingValues.addInventoryItems == 'Yes' ? true : false">
                      <label>Mandatory</label>
                    </div>
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="hiddenAddInventoryItems" :checked="userMandFieldsData != undefined && userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.hiddenAddInventoryItems != undefined && userMandFieldsData.settingValues.hiddenAddInventoryItems == 'Yes' ? true : false">
                      <label>Hidden</label>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
            <div class="unifiSettingsSection">
              <div class="appSubHeading appSubHeading--comfy appSubHeading--standalone toggleShow" @click="viewToggleMenuDatafn('activation')">
                <span>Activation</span> &nbsp;<span class="jaze-icon--pointer-right navIcon" :class='(viewToggleMenuData == "activation")? "jaze-icon--pointer-down":"jaze-icon--pointer-right"'></span>
              </div>
              <fieldset class="appFieldset appFieldset--quiet toggleContent" :style='(viewToggleMenuData == "activation")? "":"display:none"'>
                <div class="appRow">
                  <div class="col--sm5 col--md4 col--lg3">
                    <label class="appLabel appLabel--primary"> Account Activation </label>
                  </div>
                  <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <span class="jaze-icon--minus"></span>
                    </div>
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="hiddenActivationDate" :checked="userMandFieldsData != undefined && userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.hiddenActivationDate != undefined && userMandFieldsData.settingValues.hiddenActivationDate == 'Yes' ? true : false">
                      <label>Hidden</label>
                    </div>
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm5 col--md4 col--lg3">
                    <label class="appLabel appLabel--primary"> Account Expiration </label>
                  </div>
                  <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <span class="jaze-icon--minus"></span>
                    </div>
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="hiddenExpirationDate" :checked="userMandFieldsData != undefined && userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.hiddenExpirationDate != undefined && userMandFieldsData.settingValues.hiddenExpirationDate == 'Yes' ? true : false">
                      <label>Hidden</label>
                    </div>
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm5 col--md4 col--lg3">
                    <label class="appLabel appLabel--primary"> Comments </label>
                  </div>
                  <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="comments" :checked="userMandFieldsData != undefined && userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.comments != undefined && userMandFieldsData.settingValues.comments == 'Yes' ? true : false">
                      <label>Mandatory</label>
                    </div>
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="hiddencomments" :checked="userMandFieldsData != undefined && userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.hiddencomments != undefined && userMandFieldsData.settingValues.hiddencomments == 'Yes' ? true : false">
                      <label>Hidden</label>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
            <div class="unifiSettingsSection">
              <div class="appSubHeading appSubHeading--comfy appSubHeading--standalone toggleShow" @click="viewToggleMenuDatafn('notification')">
                <span>Notifications</span> &nbsp;<span class="jaze-icon--pointer-right navIcon" :class='(viewToggleMenuData == "notification")? "jaze-icon--pointer-down":"jaze-icon--pointer-right"'></span>
              </div>
              <fieldset class="appFieldset appFieldset--quiet toggleContent" :style='(viewToggleMenuData == "notification")? "":"display:none"'>
                <div class="appRow">
                  <div class="col--sm5 col--md4 col--lg3">
                    <label class="appLabel appLabel--primary"> Notify via SMS </label>
                  </div>
                  <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="notifyUserSms" :checked="userMandFieldsData != undefined && userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.notifyUserSms != undefined && userMandFieldsData.settingValues.notifyUserSms == 'Yes' ? true : false">
                      <label>Mandatory</label>
                    </div>
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="hiddenNotifyUserSms" :checked="userMandFieldsData != undefined && userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.hiddenNotifyUserSms != undefined && userMandFieldsData.settingValues.hiddenNotifyUserSms == 'Yes' ? true : false">
                      <label>Hidden</label>
                    </div>
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm5 col--md4 col--lg3">
                    <label class="appLabel appLabel--primary"> Notify via email </label>
                  </div>
                  <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="notifyUserEmail" :checked="userMandFieldsData != undefined && userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.notifyUserEmail != undefined && userMandFieldsData.settingValues.notifyUserEmail == 'Yes' ? true : false">
                      <label>Mandatory</label>
                    </div>
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="hiddenNotifyUserEmail" :checked="userMandFieldsData != undefined && userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.hiddenNotifyUserEmail != undefined && userMandFieldsData.settingValues.hiddenNotifyUserEmail == 'Yes' ? true : false">
                      <label>Hidden</label>
                    </div>
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm5 col--md4 col--lg3">
                    <label class="appLabel appLabel--primary"> Notify via Whatsapp </label>
                  </div>
                  <div class="col--sm7 col--md8 col--lg9 appFormGroup appFormGroup--condensed">
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="notifyUserWhatsapp" :checked="userMandFieldsData != undefined && userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.notifyUserWhatsapp != undefined && userMandFieldsData.settingValues.notifyUserWhatsapp == 'Yes' ? true : false">
                      <label>Mandatory</label>
                    </div>
                    <div class="appInputGroup appInputGroup--cozy inlineBlock horizontalRhythm--cozy">
                      <input type="checkbox" name="hiddenNotifyUserWhatsapp" :checked="userMandFieldsData != undefined && userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.hiddenNotifyUserWhatsapp != undefined && userMandFieldsData.settingValues.hiddenNotifyUserWhatsapp == 'Yes' ? true : false">
                      <label>Hidden</label>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
            <div class="unifiSettingsSection">
              <div class="appSubHeading appSubHeading--comfy appSubHeading--standalone toggleShow" @click="viewToggleMenuDatafn('generateRandom')">
                <span>Generate Random</span> &nbsp;<span class="jaze-icon--pointer-right navIcon" :class='(viewToggleMenuData == "generateRandom")? "jaze-icon--pointer-down":"jaze-icon--pointer-right"'></span>
              </div>
              <fieldset class="appFieldset appFieldset--quiet toggleContent" :style='(viewToggleMenuData == "generateRandom")? "":"display:none"'>
                <div class="appRow">
                  <div class="col--sm5 col--md4 col--lg3">
                    <label class="appLabel appLabel--primary"> User Name Complexity
                      <a class="jazeTooltipContainer">
                        <a @mouseover="tootipShow = true" @mouseleave="tootipShow = false" class="popovers" style="color: #666666"><i class="jaze-icon jaze-icon--info "></i> <i>note</i> </a>
                        <div class="jazeTooltip jazeTooltip--topRight jazeTooltip--small type-reset unifiDashboardTooltip" v-show='tootipShow'>
                          <div class="appDescriptionListRow">
                            Applicable only if user name perfix is not enabled
                          </div>
                        </div>
                      </a>
                    </label>
                  </div>
                  <div class="col--sm4 col--md5 col--lg5 appFormGroup appFormGroup--cozy">
                    <div class="appInputGroup verticalRhythmTop--condensed">
                      <div class="appInputGroup appInputGroup--cozy inlineBlock">
                        <input type="radio" name="generateRandomUsernameComplexity" value="n" :checked="userMandFieldsData != undefined && userMandFieldsData.settingValues != undefined && (userMandFieldsData.settingValues.generateRandomUsernameComplexity == undefined || userMandFieldsData.settingValues.generateRandomUsernameComplexity == 'n') ? true : false">
                        <label for="generateRandomUsernameComplexity"> Numbers </label>
                      </div>
                      <div class="appInputGroup appInputGroup--cozy inlineBlock">
                        <input type="radio" name="generateRandomUsernameComplexity" value="a" :checked="userMandFieldsData != undefined && userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.generateRandomUsernameComplexity != undefined && userMandFieldsData.settingValues.generateRandomUsernameComplexity == 'a' ? true : false">
                        <label for="generateRandomUsernameComplexity"> Alphabets </label>
                      </div>
                      <div class="appInputGroup appInputGroup--cozy inlineBlock">
                        <input type="radio" name="generateRandomUsernameComplexity" value="na" :checked="userMandFieldsData != undefined && userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.generateRandomUsernameComplexity != undefined && userMandFieldsData.settingValues.generateRandomUsernameComplexity == 'na' ? true : false">
                        <label for="generateRandomUsernameComplexity"> Combination of numbers and alphabets </label>
                      </div>
                      <div class="appInputGroup appInputGroup--cozy inlineBlock">
                        <input type="radio" name="generateRandomUsernameComplexity" value="nas" :checked="userMandFieldsData != undefined && userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.generateRandomUsernameComplexity != undefined && userMandFieldsData.settingValues.generateRandomUsernameComplexity == 'nas' ? true : false">
                        <label for="generateRandomUsernameComplexity"> Combination of numbers, alphabets and special characters </label>
                      </div>
                    </div>
                  </div>
                  <div class="col--sm3 col--md3 col--lg4 appFormGroup appFormGroup--cozy">
                    <div class="appRow">
                      <div class="col--sm5">
                        <label class="appLabel appLabel--primary appLabel--boxAlign floatRight">Length</label>
                      </div>
                      <div class="col--sm7 appFormGroup appFormGroup--cozy">
                        <input class="input--full" type="text" name="generateRandomUsernameLength" v-model="generateRandomUsernameLength">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="appRow">
                  <div class="col--sm5 col--md4 col--lg3">
                    <label class="appLabel appLabel--primary"> Password Complexity </label>
                  </div>
                  <div class="col--sm4 col--md5 col--lg5 appFormGroup appFormGroup--cozy">
                    <div class="appInputGroup verticalRhythmTop--condensed">
                      <div class="appInputGroup appInputGroup--cozy inlineBlock">
                        <input type="radio" name="generateRandomPasswordComplexity" value="n" :checked="userMandFieldsData != undefined && userMandFieldsData.settingValues != undefined && (userMandFieldsData.settingValues.generateRandomPasswordComplexity == undefined || userMandFieldsData.settingValues.generateRandomPasswordComplexity == 'n') ? true : false">
                        <label for="generateRandomPasswordComplexity"> Numbers </label>
                      </div>
                      <div class="appInputGroup appInputGroup--cozy inlineBlock">
                        <input type="radio" name="generateRandomPasswordComplexity" value="a" :checked="userMandFieldsData != undefined && userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.generateRandomPasswordComplexity != undefined && userMandFieldsData.settingValues.generateRandomPasswordComplexity == 'a' ? true : false">
                        <label for="generateRandomPasswordComplexity"> Alphabets </label>
                      </div>
                      <div class="appInputGroup appInputGroup--cozy inlineBlock">
                        <input type="radio" name="generateRandomPasswordComplexity" value="na" :checked="userMandFieldsData != undefined && userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.generateRandomPasswordComplexity != undefined && userMandFieldsData.settingValues.generateRandomPasswordComplexity == 'na' ? true : false">
                        <label for="generateRandomPasswordComplexity"> Combination of numbers and alphabets </label>
                      </div>
                      <div class="appInputGroup appInputGroup--cozy inlineBlock">
                        <input type="radio" name="generateRandomPasswordComplexity" value="nas" :checked="userMandFieldsData != undefined && userMandFieldsData.settingValues != undefined && userMandFieldsData.settingValues.generateRandomPasswordComplexity != undefined && userMandFieldsData.settingValues.generateRandomPasswordComplexity == 'nas' ? true : false">
                        <label for="generateRandomPasswordComplexity"> Combination of numbers, alphabets and special characters </label>
                      </div>
                    </div>
                  </div>
                  <div class="col--sm3 col--md3 col--lg4 appFormGroup appFormGroup--cozy">
                    <div class="appRow">
                      <div class="col--sm5">
                        <label class="appLabel appLabel--primary appLabel--boxAlign floatRight">Length</label>
                      </div>
                      <div class="col--sm7 appFormGroup appFormGroup--cozy">
                        <input class="input--full" type="text" name="generateRandomPasswordLength" v-model="generateRandomPasswordLength">
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
          </fieldset>
        </form>
        <div class="appFormFooter appFormFooter--standalone appFormFooter--flush appFormFooter--dark">
          <div class="appFormFooter__right" v-if="userMandFieldsData != undefined && userMandFieldsData.visible == 'visible'">
            <button class="appMainButton busyToggle" type="button" @click="subZoneModalShow=true" v-if="userMandFieldsData.edition == 'isp'"> SubZones Restrictions</button>
            <button class="appMainButton busyToggle" type="button" @click="subZoneModalShow=true" v-else> SubAccounts Restrictions</button>

            <button class="appMainButton busyToggle" type="button" @click="pushZonefn()" v-if="userMandFieldsData.edition == 'isp'"> Add to All SubZones</button>
            <button class="appMainButton busyToggle" type="button" @click="pushZonefn()" v-else> Add to All SubAccounts</button>

            <button class="appMainButton appMainButton--primary busyToggle" type="submit" @click="saveUserMandatoryFields"> Save</button>
          </div>
        </div>
      </div>
    </div>

    <div class="jazeModalContainer jazeModalContainer--blackout jazeModalContainer--centered" v-if="subZoneModalShow">
      <div class="pos-relative" dialog-window>
        <div dialog-content>
          <div class="jazeModal jazeModal--small unifiSpeedTestModal unifiSpeedTestModal--inProgress">
            <form class="jazeForm jazeModal jazeModal--small unifiAddUser">
              <div class="jazeModal__header pb-0">
                <div class="jazeHeader jazeHeader--centered">
                  <div class="jazeHeader__title"> Save </div>
                  <svg viewBox="0 0 14 14" class="jazeIcon jazeIcon--actionable" @click="subZoneModalShow=false">
                    <g transform="translate(8.5 7.2071) rotate(45) translate(-9 -9)">
                      <path d="m9.0294 8.7071h8v1h-8v8h-1v-8h-8v-1h8v-8h1v8z" />
                    </g>
                  </svg>
                </div>
              </div>
              <div class="jazeModal__main">
                <div class="jazeModalContent pb-1">
                  <div class="container container--horizontal-cozy container--vertical-comfy">
                    <div class="row">
                      <div class="col jazeFormGroup jazeFormGroup--flex">
                        <label class="jazeLabel jazeLabel--primary" v-if="userMandFieldsData != undefined && userMandFieldsData.edition =='isp'">Allow/Deny sub-zones to change user fields settings</label>
                        <label class="jazeLabel jazeLabel--primary" v-else>Allow/Deny sub-accounts to change user fields settings</label>&nbsp;
                        <div class="toggle appToggle appToggle--light" data-on="On" data-off="Off">
                          <input type="checkbox" v-model="switchcheckboxUser" id="switchcheckboxUser" :checked="userMandFieldsData != undefined && userMandFieldsData.subAccountsCanNotChangeUserFieldsValues == 'Yes' ? true : false">
                          <label for="switchcheckboxUser"></label>
                        </div>
                      </div>
                    </div>
                    <div class="appType--micro appType--quiet italicized" v-if="userMandFieldsData != undefined && userMandFieldsData.edition =='isp'">
                      Note: If it is 'ON', sub-zones can not change user fields settings.
                    </div>
                    <div class="appType--micro appType--quiet italicized" v-else>
                      Note: If it is 'ON', sub-accounts can not change user fields settings.
                    </div>
                  </div>
                </div>
              </div>
              <div class="jazeModal__footer">
                <div class="jazeButtonGroup jazeButtonGroup--cozy">
                  <button class="jazeButton jazeButton--secondary" type="button" @click="subZoneModalShow=false">Cancel</button>
                  <button class="jazeButton jazeButton--primary submitForm" type="button" @click="allowSubZones">Yes</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <SuccessToaster :successMessage='successMessage' :sucesstoasterShow='successtoaster' v-if='successtoaster'></SuccessToaster>
    <ErrorToaster :errorMessage='errorMessage' :errortoasterShow='errortoaster' v-if='errortoaster'></ErrorToaster>
    <PushToSubZone v-if="pushModal" :selected='selected' :edition="userMandFieldsData.edition" @extend-clicked-push-detail="onclickclose" :subAccounts="subaccDetailsGetter" :from="'userfieldSetting'" :modalType="'subAccountsOnly'"></PushToSubZone>

  </div>
</template>

<script>
import { ref,computed,watch,watchEffect,onMounted,onBeforeUnmount } from 'vue'
import SuccessToaster from "@/components/Toaster/SuccessToaster.vue";
import ErrorToaster from "@/components/Toaster/ErrorToaster.vue";
import PushToSubZone from "@/components/Profiles/PushToSubZoneModal.vue";
import Treeselect from "jaze-vue3-treeselect";
;
import { storeToRefs } from "pinia";
import { usesettingsStore } from '@/store/settingsstore'
import { uselayoutStore } from '@/store/layoutstore'
export default {
  setup() {
    const layoutStore = uselayoutStore()
    const { subaccDetails } = storeToRefs(layoutStore)
    const subaccDetailsGetter= computed(() => subaccDetails.value);
    const userMandFieldsData = ref( [] )
    const viewToggleMenuData = ref( "" )
    const subZoneModalShow = ref( false )
    const selectedAccVal = ref( null )
    const subAccOptions = ref( [
            {
              id: "selectAll",
              label: "Select All",
              children: [],
            },
          ] )
    const successtoaster = ref( false )
    const successMessage = ref( "" )
    const errortoaster = ref( false )
    const errorMessage = ref( "" )
    const tootipShow = ref( false )
    const generateRandomUsernameLength = ref( 8 )
    const generateRandomPasswordLength = ref( 4 )
    const switchcheckboxUser = ref( false )
    const pushModal = ref( false )
    const selected = ref( [] )
    // const subAccounts = ref( [] )

    const settingsStore = usesettingsStore()
    const { userFieldsData } = storeToRefs(settingsStore)
    const userFieldSettingsGetter = computed(() => userFieldsData.value)

    onMounted(() => {
      getUserFieldsData();
    })
    onBeforeUnmount(() => {
      settingsStore.clearSettingsState();
    })

    watchEffect(() => {
      userMandFieldsData.value = userFieldSettingsGetter.value;

      if (
        userMandFieldsData.value.settingValues != undefined &&
        userMandFieldsData.value.settingValues.generateRandomUsernameLength !=
          undefined
      ) {
        generateRandomUsernameLength.value = userMandFieldsData.value.settingValues.generateRandomUsernameLength;
      }
      if (
        userMandFieldsData.value.settingValues != undefined &&
        userMandFieldsData.value.settingValues.generateRandomPasswordLength !=
          undefined
      ) {
        generateRandomPasswordLength.value = userMandFieldsData.value.settingValues.generateRandomPasswordLength;
      }
      if (
        userMandFieldsData.value.accountsArr != undefined &&
        userMandFieldsData.value.accountsArr.length != 0
      ) {
        for (let i = 0; i < userMandFieldsData.value.accountsArr.length; i++) {
          var deactivated = userMandFieldsData.value.accountsArr[i].deactivated == 0 ? " (Deactivated)" : "";
          subAccOptions.value[0].children.push({
            id: userMandFieldsData.value.accountsArr[i].id,
            label: userMandFieldsData.value.accountsArr[i].name + deactivated,
          });
        }
      }
      if (
        userMandFieldsData.value.subAccountsCanNotChangeUserFieldsValues == "Yes"
      ) {
        switchcheckboxUser.value = true;
      }
    })

    const getUserFieldsData = () => {
      if (Object.keys(userFieldSettingsGetter.value).length == 0) {
        // $store.dispatch("fetchUserFieldSettings");
        settingsStore.fetchUserFieldSettings();
      } else {
        userMandFieldsData.value = userFieldSettingsGetter.value;
      }
    }
    const saveUserMandatoryFields = (e) => {
      successtoaster.value = false;
      errortoaster.value = false;
      e.preventDefault();
      var form = document.getElementById('UserRegistrationForm');
      const formData = new FormData(form);
      const postData = {};
      for (let [key, val] of formData.entries()) {
        Object.assign(postData, { [key]: val });
      }
      // $store.dispatch("userFieldSettingsSubmit", postData).then(
      settingsStore.userFieldSettingsSubmit(postData).then(  
        (response) => {
          successtoaster.value = true;
          successMessage.value = response;
          setTimeout(
            function () {
              successtoaster.value = false;
            }.bind(this),
            2000
          );
        },
        (error) => {
          errortoaster.value = true;
          errorMessage.value = error;
          setTimeout(
            function () {
              errortoaster.value = false;
            }.bind(this),
            15000
          );
          console.log(error);
        }
      );
    }
    const allowSubZones = () => {
      successtoaster.value = false;
      errortoaster.value = false;
      if (switchcheckboxUser.value == true) {
        var check = "on";
      } else {
        var check = "off";
      }
      var data = {
        switchCheckBoxValue: check,
        attribute: "subAccountsCanNotChangeUserFieldsValues",
        type: "userFields",
      };
      // $store.dispatch("saveSubAccountPermissionSettings", data).then(
      settingsStore.saveSubAccountPermissionSettings(data).then(  
        (response) => {
          subZoneModalShow.value = false;
          successtoaster.value = true;
          successMessage.value = response;
          setTimeout(
            function () {
              successtoaster.value = false;
            }.bind(this),
            2000
          );
        },
        (error) => {
          errortoaster.value = true;
          errorMessage.value = error;
          setTimeout(
            function () {
              errortoaster.value = false;
            }.bind(this),
            15000
          );
          console.log(error);
        }
      );
    }
    const viewToggleMenuDatafn = (value) => {
      if (viewToggleMenuData.value == value) {
        viewToggleMenuData.value = "";
      } else {
        viewToggleMenuData.value = value;
      }
    }
    const onclickclose = () => {
      pushModal.value = false;
    }
    const pushZonefn = () => {
      pushModal.value = !pushModal.value;
    }

    return {
      settingsStore,
      userFieldSettingsGetter: userFieldsData,
      subaccDetailsGetter,
      userMandFieldsData,
      viewToggleMenuData,
      subZoneModalShow,
      selectedAccVal,
      subAccOptions,
      successtoaster,
      successMessage,
      errortoaster,
      errorMessage,
      tootipShow,
      generateRandomUsernameLength,
      generateRandomPasswordLength,
      switchcheckboxUser,
      pushModal,
      selected,
      // subAccounts,
      getUserFieldsData,
      saveUserMandatoryFields,
      allowSubZones,
      viewToggleMenuDatafn,
      onclickclose,
      pushZonefn,
    }
  },
  components: {
    SuccessToaster,
    ErrorToaster,
    PushToSubZone,
    Treeselect,
  },
};
</script>
