import { createWebHistory, createRouter } from "vue-router";
const Index = () => import(/* webpackPrefetch: false */ '@/views/Index.vue')
const SigninPage = () => import(/* webpackPrefetch: false */ '@/views/login/SignIn.vue')
const ForgotPasswordPage = () => import(/* webpackPrefetch: false */ '@/views/login/ForgotPassword.vue')
const Terms = () => import(/* webpackPrefetch: false */ '@/views/login/terms_of_service.vue')
const PrivacyPolicy= () => import(/* webpackPrefetch: false */ '@/views/login/privacy_policies.vue')
const Register = () => import(/* webpackPrefetch: false */ '@/views/register/index.vue')
const FranchisePaymentGateway = () => import(/* webpackPrefetch: false */ '@/views/franchiseManagement/FranchisePaymentGateway.vue')
import dashboardRoutes from './dashboard';
import licenseRoutes from './license';
import lockscreenRoutes from './lockscreen';
import billingRoutes from './billing';
import adminRoutes from './admins';
import logRoutes from './log';
import profileRoutes from './profiles'; 
import leadRoutes from './leads';
import userRoutes from './users';
import externalIntegrationRoutes from './externalintegration';
import tasksRoutes from './tasks';
import franchiseManagementRoutes from './franchiseManagement';
import cpConfigurationRoutes from './cpconfiguration';
import cpSettingRoutes from './captiveportalsetting';
import paymentCollectionRoutes from './paymentCollection';
import accesspointsRoutes from './accesspoints';
import inventoryRoutes from './inventory';
import discountRoutes from './discount';
import announcementsRoutes from './announcements';
import networksettingsRoutes from './networksettings';
import settingsRoutes from './settings';
import acsRoutes from './acs';
import analytics from './analytics';
import statusRoutes from './status';
import iptvRoutes from './iptv';
import accountSettingRoutes from './accountsetting';
import systemtoolsRoutes from './systemtools';
import reportingRoutes from './reporting';
import overviewRoutes from './overview';
import registerRoutes from './register';
import paymentRoutes from './payment';
import monitoringRoutes from './monitoring';
import voicephoneRoutes from './voicephone';
import helpdeskRoutes from './helpdesk';
import networkdiagnosticsRoutes from './networkdiagnostics';
import pinBillingRoutes from './pinbilling';
//import pinia from "@/store/store.js";
import { uselayoutStore } from '@/store/layoutstore'
import axios from "@/api/index";
const routes = [
  {
    path: '/login/p/:id/:token',
    component: FranchisePaymentGateway,
  },
    {
        path: '/',
        name: 'Index',
        component: Index
      },
    {
        path: '/signin',
        name: 'SignIn',
        component: SigninPage,
        meta: {
          title: "Login"
        },
        beforeEnter(to, from, next) {
          if (localStorage.getItem('token')) {
            window.location.href = "/dashboard";
          } else {
            next()
          }
        }
      },
      {
        path: '/reset_password',
        component: ForgotPasswordPage
      },{
        path: '/terms',
        component: Terms
      },
      {
        path: '/privacy_policy',
        component: PrivacyPolicy
      },
      {
        path: '/register',
        component: Register,
        meta: {
          title: "Register"
        }
      },
      dashboardRoutes,
      licenseRoutes,
      lockscreenRoutes,
      adminRoutes,
      logRoutes,
      profileRoutes,
      leadRoutes,
      tasksRoutes,
      billingRoutes,
      userRoutes,
      franchiseManagementRoutes,
      externalIntegrationRoutes,
      cpConfigurationRoutes,
      cpSettingRoutes,
      paymentCollectionRoutes,
      accesspointsRoutes,
      inventoryRoutes,
      discountRoutes,
      announcementsRoutes,
      networksettingsRoutes,
      settingsRoutes,
      acsRoutes,
      analytics,
      statusRoutes,
      iptvRoutes,
      accountSettingRoutes,
      systemtoolsRoutes,
      reportingRoutes,
      overviewRoutes,
      registerRoutes,
      paymentRoutes,
      monitoringRoutes,
      voicephoneRoutes,
      helpdeskRoutes,
      networkdiagnosticsRoutes,
      pinBillingRoutes,
]
const router = createRouter({
    history: createWebHistory(),
    routes,
  });
  import { event } from 'vue-gtag'
  //if (localStorage.getItem('useGoogleAnalytics')=="yes") {
    // Vue.use(VueAnalytics, {
    //   id: 'UA-41407586-1',// this code is for google analytics
    //   router
    // })
  //}
  import {storeToRefs} from "pinia";
  router.beforeEach(async (to, from, next) => {  
    
    if (localStorage.getItem('useGoogleAnalytics')=="yes") {
      if(to != undefined && to.path != undefined){
     //   gtag.pageview(to.path);
        console.log("to.path: ",event);
        event('page_view', to.path)
      }
    }
    next()
    
    const uselayout = uselayoutStore();
    const { headerDetailsGetter } = storeToRefs(uselayout)
    if (to.meta.title != undefined && headerDetailsGetter.value.meta_header != undefined) {
      document.title = headerDetailsGetter.value.meta_header.title + " : " + to.meta.title
    } else {
      axios
        .get("/login/get_signin_logo")
        .then((response) => {
          if (response.data.meta_data != undefined) {
            document.title = response.data.meta_data.title + " : " + to.meta.title
          }
        })
        .catch((error) => console.log(error));
   }
  })
  
  export default router;