<template>
  <div>
      <div class="unifiSettingsSection">
        <div class="appRow">
            <div class="col--sm5">
                <label class="appLabel appLabel--primary appLabel--boxAlign"> Aadhaar DOB </label>
            </div>
            <div class="col--sm5 appFormGroup appFormGroup--cozy">
                <treeselect  :multiple="false" v-model="additionalFieldId" :options="options" :searchable="true" :clearable="false" :default-expand-level="1"></treeselect>
            </div>
        </div>
        <div class="appFormFooter appFormFooter--flush appFormFooter--dark">
            <div class="appFormFooter__right">
                <button class="jazeButton jazeButton--primary" type="button" @click="saveAadharDobValue">Save</button>            
            </div>
        </div>
      </div>
    <SuccessToaster :successMessage='successMessage' :sucesstoasterShow='successtoaster' v-if='successtoaster'></SuccessToaster>
    <ErrorToaster :errorMessage='errorMessage' :errortoasterShow='errortoaster' v-if='errortoaster'></ErrorToaster>      
  </div>
</template>
<script>
import { ref,toRef,computed,onMounted } from 'vue';
import Treeselect from "jaze-vue3-treeselect";
import SuccessToaster from "@/components/Toaster/SuccessToaster.vue";
import ErrorToaster from "@/components/Toaster/ErrorToaster.vue";
import { usesettingsStore } from '@/store/settingsstore';
import {overrideIsSelected} from '@/overrides/vue3-treeselect'
export default {
    props: ["additionalFields", "additionalFieldMappingId"],  
    components: {
        Treeselect,
        SuccessToaster,
        ErrorToaster
    },
    mixins: [overrideIsSelected()],
    setup(props) {
        const  additionalFields = toRef(props,'additionalFields'); 
        const additionalFieldMappingId  = toRef(props,'additionalFieldMappingId');
        const additionalFieldId = ref( "" )
        const errorShow = ref( false )
        const errorMessage = ref( "" )
        const successtoaster = ref( false )
        const successMessage = ref( "" )
        const errortoaster = ref( false )
        const metaFieldOptions = computed(() => {
            console.log('hiii');
            console.log('fields',additionalFields.value);
            var metaFieldArr = [{
                id: '',
                label: 'None'
            }];
            if (
                additionalFields.value != undefined &&
                additionalFields.value.length != 0
            ) {
                for (let i = 0; i < additionalFields.value.length; i++) {
                    metaFieldArr.push({
                        id: additionalFields.value[i].MetaFieldName.id,
                        label: additionalFields.value[i].MetaFieldName.name,
                    });
                }
            }
            additionalFieldId.value = additionalFieldMappingId.value;
            console.log('meta',metaFieldArr);
            return metaFieldArr;
        })
        const options = metaFieldOptions.value;
        const settingsStore = usesettingsStore();
        

        const saveAadharDobValue = () => {
            var data = {
                additional_field_id: additionalFieldId.value
            };
            // $store.dispatch("additionalFieldMappingValueSubmit", data).then(
            settingsStore.additionalFieldMappingValueSubmit(data).then(    
                (response) => {
                successtoaster.value = true;
                successMessage.value = response.message;
                setTimeout(
                    function () {
                    successtoaster.value = false;
                    }.bind(this),
                    3500
                );
                },
                (error) => {
                errortoaster.value = true;
                errorMessage.value = error;
                setTimeout(
                    function () {
                    errortoaster.value = false;
                    }.bind(this),
                    5500
                );
                console.log(error);
                }
            );
        }

        return {
            settingsStore,
            additionalFields,
            additionalFieldMappingId,
            metaFieldOptions,
            additionalFieldId,
            errorShow,
            errorMessage,
            successtoaster,
            successMessage,
            errortoaster,
            options,
            saveAadharDobValue,
        }
    },
    
};

</script>