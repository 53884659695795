<template>
  <div class="jazeMainPanel" style="height: 100%;">
    <div class="jazePanelContent jazePanelContent--scrollable">
      <div class="unifiSettingsContainer">
        <form ref="generalConfigForm" id="generalConfigForm" class="appForm appForm--cozy is-validation-hidden" :class="formClass">
          <div class="unifiSettingsHeader">
            <div class="unifiSettingsHeader__title">General Configuration</div>
          </div>
          <fieldset class="appFieldset appFieldset--quiet">
            <div class="jazeAlert jazeAlert--danger row col-12" v-if='errorShow'>
              <div class="col-6">
                <strong>{{errorMessage}}</strong>
              </div>
            </div>
            <div class="unifiSettingsSection">
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">Organization Name</label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <input class="input--full" type="text" name="companyName" autofocus="true" v-model="accountName">
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign" v-if="genConfigDetails.edition=='isp'">
                    Zone Name
                    <a class="jazeTooltipContainer">
                      <a @mouseover="tootipShow = true" @mouseleave="tootipShow = false" class="popovers" style="color: #666666"><i class="jaze-icon jaze-icon--info "></i> <i>note</i> </a>
                      <div class="jazeTooltip jazeTooltip--topRight jazeTooltip--small type-reset unifiDashboardTooltip" v-if='tootipShow'>
                        <div class="appDescriptionListRow">
                          A unique name to identify this zone. The value can be alphanumeric and can contain "-" and "_"
                        </div>
                      </div>
                    </a>
                  </label>
                  <label class="appLabel appLabel--primary appLabel--boxAlign" v-else>
                    Account Name
                    <a class="jazeTooltipContainer">
                      <a @mouseover="tootipShow = true" @mouseleave="tootipShow = false" class="popovers" style="color: #666666"><i class="jaze-icon jaze-icon--info "></i> <i>note</i> </a>
                      <div class="jazeTooltip jazeTooltip--topRight jazeTooltip--small type-reset unifiDashboardTooltip" v-if='tootipShow'>
                        <div class="appDescriptionListRow">
                          A unique name to identify this account. The value can be alphanumeric and can contain "-" and "_"
                        </div>
                      </div>
                    </a>
                  </label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <input class="input--full" type="text" name="accountIdentifier" v-model="accountIdentifier">
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">Email</label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <input type="hidden" name="emailSettingId" v-model="emailId">
                  <input class="input--full" type="text" name="email" required oninvalid="this.setCustomValidity(' ')" oninput="this.setCustomValidity('')" v-model="emailVal">
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">Phone</label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <input type="hidden" name="phoneSettingId" v-model="phoneId">
                  <input class="input--full" type="text" name="phone" required oninvalid="this.setCustomValidity(' ')" oninput="this.setCustomValidity('')" v-model="phoneVal">
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">Email Sender Name</label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <input type="hidden" name="settingId" v-model="settingId">
                  <input class="input--full" type="text" name="emailSenderName" v-model="emailSenderName">
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">Timezone</label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <treeselect name="timeZone" v-if="genConfigDetails.timezone!=undefined" v-model="genConfigDetails.timezone" :multiple="false" :options="timezoneOptions" :searchable="true" :clearable="false" placeholder="Search"></treeselect>
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">Currency</label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <treeselect name="currency" v-if="genConfigDetails.currency!=undefined" v-model="genConfigDetails.currency" :options="currencyOptions" :searchable="true" :clearable="false" placeholder="Search"></treeselect>
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">ISD code</label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <treeselect name="isd" v-if="genConfigDetails.isd!=undefined" v-model="genConfigDetails.isd" :options="isdOptions" :searchable="true" :clearable="false" placeholder="Search"></treeselect>
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">Twitter Link</label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <input type="hidden" name="twitterLinkSettingId" v-model="twitterLinkSettingId">
                  <input class="input--full" type="text" name="twitterLink" v-model="twitterLink">
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">Facebook Link</label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <input type="hidden" name="facebookLinkSettingId" v-model="facebookLinkSettingId">
                  <input class="input--full" type="text" name="facebookLink" v-model="facebookLink">
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">Instagram Link</label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <input type="hidden" name="instagramLinkSettingId" v-model="instagramLinkSettingId">
                  <input class="input--full" type="text" name="instagramLink" v-model="instagramLink">
                </div>
              </div>
              <div class="appRow">
                <div class="col--sm3">
                  <label class="appLabel appLabel--primary appLabel--boxAlign">LinkedIn Link</label>
                </div>
                <div class="col--sm5 appFormGroup appFormGroup--cozy">
                  <input type="hidden" name="linkedinLinkSettingId" v-model="linkedinLinkSettingId">
                  <input class="input--full" type="text" name="linkedinLink" v-model="linkedinLink">
                </div>
              </div>
            </div>
          </fieldset>
        </form>
        <div class="appFormFooter appFormFooter--standalone appFormFooter--flush appFormFooter--dark">
          <div class="appFormFooter__right">
            <button class="appMainButton appMainButton--primary busyToggle" type="submit" @click="saveGeneralConfig"> Save</button>
          </div>
        </div>
      </div>
    </div>
    <SuccessToaster :successMessage='successMessage' :sucesstoasterShow='successtoaster' v-if='successtoaster'></SuccessToaster>
    <ErrorToaster :errorMessage='errorMessage' :errortoasterShow='errortoaster' v-if='errortoaster'></ErrorToaster>
  </div>
</template>

<script>
import { ref,computed,watch,watchEffect,onMounted,onBeforeUnmount } from 'vue';
import Treeselect from "jaze-vue3-treeselect";
import SuccessToaster from "@/components/Toaster/SuccessToaster.vue";
import ErrorToaster from "@/components/Toaster/ErrorToaster.vue";

import { storeToRefs } from "pinia";
import {overrideIsSelected} from '@/overrides/vue3-treeselect'
import { uselayoutStore } from '@/store/layoutstore'
import { usesettingsStore } from '@/store/settingsstore'
export default {
  mixins: [overrideIsSelected()],
  setup() {
    const genConfigDetails = ref( [] )
    const formClass = ref( "" )
    const errorShow = ref( false )
    const errorMessage = ref( "" )
    const currency = ref( null )
    const timeZone = ref( null )
    const isd = ref( null )
    const tootipShow = ref( false )
    const timezoneOptions = ref( [] )
    const currencyOptions = ref( [] )
    const isdOptions = ref( [] )
    const successtoaster = ref( false )
    const successMessage = ref( "" )
    const errortoaster = ref( false )
    const accountName = ref( "" )
    const accountIdentifier = ref( "" )
    const emailVal = ref( "" )
    const emailId = ref( "" )
    const phoneVal = ref( "" )
    const phoneId = ref( "" )
    const settingId = ref( "" )
    const emailSenderName = ref( "" )
    const twitterLinkSettingId = ref( "" )
    const twitterLink = ref( "" )
    const facebookLinkSettingId = ref( "" )
    const facebookLink = ref( "" )
    const instagramLinkSettingId = ref( "" )
    const instagramLink = ref( "" )
    const linkedinLinkSettingId = ref( "" )
    const linkedinLink = ref( "" )

    const settingsStore = usesettingsStore()
    const { generalConfigDetails } = storeToRefs(settingsStore)
    const generalConfigGetter = computed(() => generalConfigDetails.value);
    const layoutStore = uselayoutStore()  
    const { bankCurrencyCountryListData } = storeToRefs(layoutStore)
    const bankCurrencyCountryListGetter = computed(() => bankCurrencyCountryListData.value)

    onMounted(() => {
      var required = new Array();
      if(bankCurrencyCountryListGetter.value == undefined || (bankCurrencyCountryListGetter.value != undefined && Object.keys(bankCurrencyCountryListGetter.value).length == 0) ||
        (Object.keys(bankCurrencyCountryListGetter.value).length > 0 && (bankCurrencyCountryListGetter.value.currencyLists == undefined || (bankCurrencyCountryListGetter.value.currencyLists != undefined && bankCurrencyCountryListGetter.value.currencyLists.length == 0)))){
        required.push("currencyLists");
      }
      if(bankCurrencyCountryListGetter.value == undefined || (bankCurrencyCountryListGetter.value != undefined && Object.keys(bankCurrencyCountryListGetter.value).length == 0) ||
        (Object.keys(bankCurrencyCountryListGetter.value).length > 0 && (bankCurrencyCountryListGetter.value.timeZoneList == undefined || (bankCurrencyCountryListGetter.value.timeZoneList != undefined && bankCurrencyCountryListGetter.value.timeZoneList.length == 0)))){
        required.push("timeZoneList");
      }
      if(bankCurrencyCountryListGetter.value == undefined || (bankCurrencyCountryListGetter.value != undefined && Object.keys(bankCurrencyCountryListGetter.value).length == 0) ||
        (Object.keys(bankCurrencyCountryListGetter.value).length > 0 && (bankCurrencyCountryListGetter.value.isdCodes == undefined || (bankCurrencyCountryListGetter.value.isdCodes != undefined && bankCurrencyCountryListGetter.value.isdCodes.length == 0)))){
        required.push("isdCodes");
      }
      if(required.length > 0){
        layoutStore.fetchBankCurrencyCountryDetails(required);
      }
      getConfigDetails();
    })
    onBeforeUnmount(() => {
      settingsStore.clearSettingsState();
    })

    watchEffect(() => {
      if(bankCurrencyCountryListGetter.value != undefined){
        if(bankCurrencyCountryListGetter.value.currencyLists != undefined && bankCurrencyCountryListGetter.value.currencyLists.length != 0) {
          currencyOptions.value = bankCurrencyCountryListGetter.value.currencyLists;
        }
        if(bankCurrencyCountryListGetter.value.timeZoneList != undefined && bankCurrencyCountryListGetter.value.timeZoneList.length != 0) {
          timezoneOptions.value = bankCurrencyCountryListGetter.value.timeZoneList;
        }
        if(bankCurrencyCountryListGetter.value.isdCodes != undefined && bankCurrencyCountryListGetter.value.isdCodes.length != 0) {
          isdOptions.value = bankCurrencyCountryListGetter.value.isdCodes;
        }
      }
    })

    watch(generalConfigGetter,() => {
      genConfigDetails.value = generalConfigGetter.value;
      // timezoneOptions.value = genConfigDetails.value.timeZoneList;
      // currencyOptions.value = genConfigDetails.value.currencyList;
      // isdOptions.value = genConfigDetails.value.isdCodes;
      timeZone.value = genConfigDetails.value.timezone;
      currency.value = genConfigDetails.value.currency;
      isd.value = genConfigDetails.value.isd;
      accountName.value = genConfigDetails.value.accountName;
      accountIdentifier.value = genConfigDetails.value.accountIdentifier;
      if (
        genConfigDetails.value.email != undefined &&
        genConfigDetails.value.email.Setting != undefined &&
        genConfigDetails.value.email.Setting.id != ""
      ) {
        emailId.value = genConfigDetails.value.email.Setting.id;
      }
      if (
        genConfigDetails.value.email != undefined &&
        genConfigDetails.value.email.Setting != undefined &&
        genConfigDetails.value.email.Setting.value != ""
      ) {
        emailVal.value = genConfigDetails.value.email.Setting.value;
      }
      if (
        genConfigDetails.value.phone != undefined &&
        genConfigDetails.value.phone.Setting != undefined &&
        genConfigDetails.value.phone.Setting.id != ""
      ) {
        phoneId.value = genConfigDetails.value.phone.Setting.id;
      }
      if (
        genConfigDetails.value.phone != undefined &&
        genConfigDetails.value.phone.Setting != undefined &&
        genConfigDetails.value.phone.Setting.value != ""
      ) {
        phoneVal.value = genConfigDetails.value.phone.Setting.value;
      }
      if (
        genConfigDetails.value.emailSenderName != undefined &&
        genConfigDetails.value.emailSenderName.Setting != undefined &&
        genConfigDetails.value.emailSenderName.Setting.id != ""
      ) {
        settingId.value = genConfigDetails.value.emailSenderName.Setting.id;
      }
      if (
        genConfigDetails.value.emailSenderName != undefined &&
        genConfigDetails.value.emailSenderName.Setting != undefined &&
        genConfigDetails.value.emailSenderName.Setting.value != ""
      ) {
        emailSenderName.value = genConfigDetails.value.emailSenderName.Setting.value;
      }
      if (
        genConfigDetails.value.twitterLink != undefined &&
        genConfigDetails.value.twitterLink.Setting != undefined &&
        genConfigDetails.value.twitterLink.Setting.id != ""
      ) {
        twitterLinkSettingId.value = genConfigDetails.value.twitterLink.Setting.id;
      }
      if (
        genConfigDetails.value.twitterLink != undefined &&
        genConfigDetails.value.twitterLink.Setting != undefined &&
        genConfigDetails.value.twitterLink.Setting.value != ""
      ) {
        twitterLink.value = genConfigDetails.value.twitterLink.Setting.value;
      }
      if (
        genConfigDetails.value.facebookLink != undefined &&
        genConfigDetails.value.facebookLink.Setting != undefined &&
        genConfigDetails.value.facebookLink.Setting.id != ""
      ) {
        facebookLinkSettingId.value = genConfigDetails.value.facebookLink.Setting.id;
      }
      if (
        genConfigDetails.value.facebookLink != undefined &&
        genConfigDetails.value.facebookLink.Setting != undefined &&
        genConfigDetails.value.facebookLink.Setting.value != ""
      ) {
        facebookLink.value = genConfigDetails.value.facebookLink.Setting.value;
      }
      if (
        genConfigDetails.value.instagramLink != undefined &&
        genConfigDetails.value.instagramLink.Setting != undefined &&
        genConfigDetails.value.instagramLink.Setting.id != ""
      ) {
        instagramLinkSettingId.value = genConfigDetails.value.instagramLink.Setting.id;
      }
      if (
        genConfigDetails.value.instagramLink != undefined &&
        genConfigDetails.value.instagramLink.Setting != undefined &&
        genConfigDetails.value.instagramLink.Setting.value != ""
      ) {
        instagramLink.value = genConfigDetails.value.instagramLink.Setting.value;
      }
      if (
        genConfigDetails.value.linkedinLink != undefined &&
        genConfigDetails.value.linkedinLink.Setting != undefined &&
        genConfigDetails.value.linkedinLink.Setting.id != ""
      ) {
        linkedinLinkSettingId.value = genConfigDetails.value.linkedinLink.Setting.id;
      }
      if (
        genConfigDetails.value.linkedinLink != undefined &&
        genConfigDetails.value.linkedinLink.Setting != undefined &&
        genConfigDetails.value.linkedinLink.Setting.value != ""
      ) {
        linkedinLink.value = genConfigDetails.value.linkedinLink.Setting.value;
      }
      if (isdOptions.value != undefined && isdOptions.value.length != 0) {
        for (let i = 0; i < isdOptions.value.length; i++) {
          if (
            isdOptions.value[i].id == null &&
            isdOptions.value[i].label == null
          ) {
            delete isdOptions.value[i];
          }
        }
      }
    })

    const getConfigDetails = () => {
      if (Object.keys(generalConfigGetter.value).length == 0) {
        // $store.dispatch("fetchGeneralDetails");
        settingsStore.fetchGeneralDetails();
      } else {
        genConfigDetails.value = generalConfigGetter.value;
      }
      if(bankCurrencyCountryListGetter.value != undefined){
        if(bankCurrencyCountryListGetter.value.currencyLists != undefined && bankCurrencyCountryListGetter.value.currencyLists.length != 0) {
          currencyOptions.value = bankCurrencyCountryListGetter.value.currencyLists;
        }
        if(bankCurrencyCountryListGetter.value.timeZoneList != undefined && bankCurrencyCountryListGetter.value.timeZoneList.length != 0) {
          timezoneOptions.value = bankCurrencyCountryListGetter.value.timeZoneList;
        }
        if(bankCurrencyCountryListGetter.value.isdCodes != undefined && bankCurrencyCountryListGetter.value.isdCodes.length != 0) {
          isdOptions.value = bankCurrencyCountryListGetter.value.isdCodes;
        }
      }
    }
    const saveGeneralConfig = (e) => {
      successtoaster.value = false;
      errortoaster.value = false;
      e.preventDefault();
      errorShow.value = false;
      var form = document.getElementById('generalConfigForm');
      const formData = new FormData(form); // reference to form element
      const postData = {}; // need to convert it before using not with XMLHttpRequest
      for (let [key, val] of formData.entries()) {
        Object.assign(postData, { [key]: val });
      }
      var status = checkForm(e, postData);
      if (status == "error") {
        formClass.value = "is-form-validation-visible";
        return;
      }
      // $store.dispatch("generalConfigurationSubmit", postData).then(
      settingsStore.generalConfigurationSubmit(postData).then(  
        response => {
          successtoaster.value = true;
          successMessage.value = response;
          setTimeout(
            function() {
              successtoaster.value = false;
            }.bind(this),
            2000
          );
        },
        error => {
          errortoaster.value = true;
          errorMessage.value = error;
          setTimeout(
            function() {
              errortoaster.value = false;
            }.bind(this),
            15000
          );
          console.log(error);
        }
      );
    }
    const checkForm = (e, postData) => {
      if (postData.phone == "") {
        errorMessage.value = "Phone number is Required";
        errorShow.value = true;
        return "error";
      }
      if (postData.email == "") {
        errorMessage.value = "Email is Required";
        errorShow.value = true;
        return "error";
      }
      var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
      if (reg.test(postData.email) == false) {
        errorMessage.value = "Invalid Email";
        errorShow.value = true;
        return "error";
      }
    }

    return {
      settingsStore,
      genralConfigGetter: generalConfigDetails,
      bankCurrencyCountryListGetter,
      genConfigDetails,
      formClass,
      errorShow,
      errorMessage,
      currency,
      timeZone,
      isd,
      tootipShow,
      timezoneOptions,
      currencyOptions,
      isdOptions,
      successtoaster,
      successMessage,
      errortoaster,
      accountName,
      accountIdentifier,
      emailVal,
      emailId,
      phoneVal,
      phoneId,
      settingId,
      emailSenderName,
      twitterLinkSettingId,
      twitterLink,
      facebookLinkSettingId,
      facebookLink,
      instagramLinkSettingId,
      instagramLink,
      linkedinLinkSettingId,
      linkedinLink,
      getConfigDetails,
      saveGeneralConfig,
      checkForm,
    }
  },
  components: {
    Treeselect,
    SuccessToaster,
    ErrorToaster
  },
};
</script>
