<template>
  <div class="jazeMainPanel" style="height: 100%;">
    <div class="jazePanelContent jazePanelContent--scrollable">
      <div class="unifiSettingsContainer">
        <div class="unifiSettingsHeader">
          <div class="unifiSettingsHeader__left">
            <div class="unifiSettingsHeader__title">Custom Rules</div>
          </div>
          <div class="unifiSettingsHeader__right">
            <div class="appActionBarFilters py-1">
              <div class="appActionBarFilters">
                <button @click="addRule" class="jazeButton jazeButton--primary" title="Add Billing Period">
                  <span class="jaze-icon--plus"></span> &nbsp;Add
                </button>
              </div>
            </div>
          </div>
        </div>
        <fieldset class="appFieldset appFieldset--quiet">
          <div class="unifiSettingsSection">
            <table class="jazeTable jazeTable--hoverable mt-1 is-not-responsive">
              <thead>
                <tr>
                  <th>
                    <span>#</span>
                  </th>
                  <th>
                    <span>Name</span>
                  </th>
                  <th>
                    <span>Description</span>
                  </th>
                  <th>
                    <span>Status</span>
                  </th>
                  <th>
                  </th>
                  <th class="actions jazeTableCell--withActions jazeTableCell--smash"></th>
                </tr>
              </thead>
              <tbody class="jazeTableBody">
                <tr v-if="customRulesToDisplay != undefined && customRulesToDisplay.length !=0" v-for="(rule, index) in customRulesToDisplay">
                  <td>
                    <span> {{index + 1}} </span>
                  </td>
                  <td>
                    <span> {{rule.name}} </span>
                  </td>
                  <td>
                    <span v-if="!(rule.label=='GST Number Present')">
                      <b>IF </b> [{{rule.label}} {{rule.symbol}} {{rule.value}}]
                      <b>THEN </b> [ <i>{{rule.action}} </i> : {{rule.labelEncoded}}]
                    </span>
                    <span v-else>
                      <b>IF </b> [{{rule.label}}]
                      <b>THEN </b> [ <i>{{rule.action}} </i> : {{rule.labelEncoded}}]
                    </span>
                  </td>
                  <td>
                    <span> {{rule.status}} </span>
                  </td>
                  <td class="lastUpdated jazeTableCell--withActions jazeTableCell--smash pr-0">
                    <div class="jazeTableCell__actions jazeTableCell__actions--inline">
                      <div class="jazeButtonGroup">
                        <button title="Edit" class="jazeButton jazeButton--link jazeButton--noWrap mr-4" type="button" @click="editCustomRule(rule)">
                          <span class="button__icon icon jaze-icon--pencil"></span><span class="button__label">Edit</span>
                        </button>
                        <button title="Duplicate" class="jazeButton jazeButton--link jazeButton--noWrap mr-4" type="button" @click="duplicateCustomRule(rule)">
                          <span class="button__icon icon  jaze-icon--clipboard"></span><span class="button__label">Duplicate</span>
                        </button>
                        <button title="Delete" class="jazeButton jazeButton--link jazeButton--noWrap mr-4" type="button" @click="deleteCustomRule(rule)">
                          <span class="button__icon icon jaze-icon--trashcan"></span><span class="button__label">Delete</span>
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </fieldset>

        <div class="appFormFooter appFormFooter--standalone appFormFooter--flush appFormFooter--dark">
          <div class="appFormFooter__right" v-if="customRulesData.visible == 'visible'">
            <button class="appMainButton busyToggle" type="button" @click="subZoneModalShow=true" title="SubZones Restriction" v-if="customRulesData.edition == 'isp'"> SubZones Restrictions</button>
            <button class="appMainButton busyToggle" type="button" @click="subZoneModalShow=true" title="SubAccounts Restriction" v-else> SubAccounts Restrictions</button>
            <button class="appMainButton busyToggle" type="button" @click="pushZonefn()" v-if="customRulesData.edition == 'isp'"> Add to All SubZones</button>
            <button class="appMainButton busyToggle" type="button" @click="pushZonefn()" v-else> Add to All SubAccounts</button>
          </div>
        </div>

      </div>
    </div>

    <div class="jazeModalContainer jazeModalContainer--blackout jazeModalContainer--centered" v-if="customRuleAddEditModal">
      <div class="pos-relative" dialog-window>
        <div dialog-content>
          <div class="jazeModal jazeModal--medium unifiSpeedTestModal unifiSpeedTestModal--inProgress">
            <form ref="addPeriodForm" class="appForm appForm--cozy is-validation-hidden" :class="formClass">
              <div class="jazeModal__header pb-0">
                <div class="jazeHeader jazeHeader--centered">
                  <div class="jazeHeader__title"> {{action}} Rule </div>
                  <svg viewBox="0 0 14 14" class="jazeIcon jazeIcon--actionable" @click="customRuleAddEditModal=false">
                    <g transform="translate(8.5 7.2071) rotate(45) translate(-9 -9)">
                      <path d="m9.0294 8.7071h8v1h-8v8h-1v-8h-8v-1h8v-8h1v8z" />
                    </g>
                  </svg>
                </div>
              </div>
              <div class="jazeAlert jazeAlert--danger row col-12" v-show='errorShow'>
                <div class="col-6">
                  <strong>{{errorMessage}}</strong>
                </div>
              </div>
              <div class="jazeModal__main" style="overflow:auto;">
                <div class="jazeModalContent pb-1">
                  <div class="container container--horizontal-cozy container--vertical-comfy">
                    <div class="row">
                      <form ref="addEditRuleForm" id="addEditRuleForm" class="col--sm12">
                        <div class="col jazeFormGroup">
                          <div class="appRow">
                            <div class="col--sm3">
                              <label class="appLabel appLabel--boxInline">Name</label>
                            </div>
                            <div class="col--sm3 appFormGroup appFormGroup--cozy">
                              <input type="text" class="input--full" name="name" v-model="ruleName" required oninvalid="this.setCustomValidity(' ')" oninput="this.setCustomValidity('')" placeholder="Rule Name" maxlength="255" />
                            </div>
                          </div>
                          <div class="appRow">
                            <div class="col--sm3">
                              <label class="appLabel appLabel--boxInline">IF</label>
                            </div>
                            <div class="col--sm3 appFormGroup appFormGroup--cozy">
                              <select class="input--full lineHeight--normal without-standard-validation" name="field" v-model="ruleField" @change="getLabelNameFromId(ruleField,'fromSelect')">
                                <option v-if="customRulesData.requestAccess !=undefined && customRulesData.requestAccess.length!=0" v-for="access in customRulesData.requestAccess" :value="access.RequestAccessCustomField.id">
                                  <span v-if="access.RequestAccessCustomField.field_type != 'check_box' && access.RequestAccessCustomField.field_type != 'dob'">
                                    {{access.RequestAccessCustomField.label_name}}
                                  </span>
                                </option>
                              </select>
                            </div>
                            <div class="col--sm3 appFormGroup appFormGroup--cozy" v-if="!(ruleFieldLabel=='GST Number Present')">
                              <select class="input--full lineHeight--normal without-standard-validation" name="symbol" required oninvalid="this.setCustomValidity(' ')" oninput="this.setCustomValidity('')" v-model="ruleSymbol">
                                <option value="==">Equal to</option>
                              </select>
                            </div>
                            <div v-else>
                              <input type="hidden" name="symbol" value="==" />
                            </div>
                            <div class="col--sm3 appFormGroup appFormGroup--cozy" v-if="!(ruleFieldLabel=='GST Number Present')">
                              <input type="text" class="input--full" name="value" v-model="ruleValue" required oninvalid="this.setCustomValidity(' ')" oninput="this.setCustomValidity('')" placeholder="Enter value" maxlength="128" />
                            </div>
                          </div>
                          <div class="appRow">
                            <div class="col--sm3">
                              <label class="appLabel appLabel--boxInline">Action</label>
                            </div>
                            <div class="col--sm3 appFormGroup appFormGroup--cozy">
                              <select class="input--full lineHeight--normal without-standard-validation" name="action" v-model="ruleAction" @change="hideShowTextField">
                                <option v-if="!(ruleFieldLabel=='GST Number Present')" value="invoice_template"> Invoice Template </option>
                                <option v-if="!(ruleFieldLabel=='GST Number Present')" value="payment_gateway"> Payement Gateway </option>
                                <option v-if="!(ruleFieldLabel=='GST Number Present')" value="excluded_in_revenue"> Exclude in Revenue </option>
                                <option v-if="!(ruleFieldLabel=='GST Number Present')" value="include_in_revenue"> Include in Revenue </option>
                                <option v-if="!(ruleFieldLabel=='GST Number Present')" value="excluded_in_discount"> Exclude in Discount </option>
                                <option v-if="!(ruleFieldLabel=='GST Number Present')" value="exclude_fixed_rate"> Exclude Fixed Rate in Revenue </option>
                                <option v-if="(ruleFieldLabel=='GST Number Present')" value="exclude_cess"> Exclude CESS</option>
                              </select>
                            </div>
                            <div class="col--sm3 appFormGroup appFormGroup--cozy" v-if="actionField">
                              <input type="text" class="input--full" name="action_values" v-model="ruleActionValues" :placeholder="actionPlaceHolder" />
                            </div>
                          </div>
                          <div class="appRow">
                            <div class="col--sm3">
                              <label class="appLabel appLabel--boxInline">Status</label>
                            </div>
                            <div class="col--sm3 appFormGroup appFormGroup--cozy">
                              <select class="input--full lineHeight--normal without-standard-validation" name="status" v-model="ruleStatus">
                                <option value="active">Active</option>
                                <option value="inactive">Disable</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div class="jazeModal__footer">
                <div class="jazeButtonGroup jazeButtonGroup--cozy">
                  <button class="jazeButton jazeButton--secondary" type="button" @click="customRuleAddEditModal=false">Cancel</button>
                  <button class="jazeButton jazeButton--primary submitForm" type="button" @click="addEditCustomRule">Add rule</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="jazeModalContainer jazeModalContainer--blackout jazeModalContainer--centered" v-if="deleteConfirmModal">
      <div class="pos-relative" dialog-window>
        <div dialog-content>
          <div class="jazeModal jazeModal--small unifiSpeedTestModal unifiSpeedTestModal--inProgress">
            <form class="jazeForm jazeModal jazeModal--small unifiAddUser">
              <div class="jazeModal__header pb-0">
                <div class="jazeHeader jazeHeader--centered">
                  <div class="jazeHeader__title"> Delete rule? </div>
                  <svg viewBox="0 0 14 14" class="jazeIcon jazeIcon--actionable" @click="deleteConfirmModal=false">
                    <g transform="translate(8.5 7.2071) rotate(45) translate(-9 -9)">
                      <path d="m9.0294 8.7071h8v1h-8v8h-1v-8h-8v-1h8v-8h1v8z" />
                    </g>
                  </svg>
                </div>
              </div>
              <div class="jazeModal__main">
                <div class="jazeModalContent pb-1">
                  <div class="container container--horizontal-cozy container--vertical-comfy">
                    <div class="row">
                      <div class="col jazeFormGroup jazeFormGroup--flex">
                        <label class="jazeLabel jazeLabel--primary">
                          Are you sure want to delete the rule <b>{{nameToDelete}}</b> ?
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="jazeModal__footer">
                <div class="jazeButtonGroup jazeButtonGroup--cozy">
                  <button class="jazeButton jazeButton--secondary" type="button" @click="deleteConfirmModal=false">Cancel</button>
                  <button class="jazeButton jazeButton--primary submitForm" type="button" @click="deletePeriodConfirm">Yes</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="jazeModalContainer jazeModalContainer--blackout jazeModalContainer--centered" v-if="subZoneModalShow" style="display:flex">
      <div class="pos-relative" dialog-window>
        <div dialog-content>
          <div class="jazeModal jazeModal--small unifiSpeedTestModal unifiSpeedTestModal--inProgress">
            <form class="jazeForm jazeModal jazeModal--small unifiAddUser">
              <div class="jazeModal__header pb-0">
                <div class="jazeHeader jazeHeader--centered">
                  <div class="jazeHeader__title" v-if="customRulesData.edition =='isp'"> Subzone Restriction </div>
                  <div class="jazeHeader__title" v-else> Subaccount Restriction </div>
                  <svg viewBox="0 0 14 14" class="jazeIcon jazeIcon--actionable" @click="subZoneModalShow=false">
                    <g transform="translate(8.5 7.2071) rotate(45) translate(-9 -9)">
                      <path d="m9.0294 8.7071h8v1h-8v8h-1v-8h-8v-1h8v-8h1v8z" />
                    </g>
                  </svg>
                </div>
              </div>
              <div class="jazeModal__main" style="overflow:auto;">
                <div class="jazeModalContent pb-1">
                  <div class="container container--horizontal-cozy container--vertical-comfy">
                    <div class="row">
                      <div class="col jazeFormGroup jazeFormGroup--flex col-12">
                        <label class="jazeLabel jazeLabel--primary col-8" for="notifyViaEmail" v-if="customRulesData.edition =='isp'">Allow/Deny sub-zones to change custom rules</label>
                        <label class="jazeLabel jazeLabel--primary col-8" for="notifyViaEmail" v-else>Allow/Deny sub-accounts to change custom rules</label>
                        <div class="col-1"> </div>
                        <div class="toggle appToggle appToggle--light" data-on="On" data-off="Off">
                          <input type="checkbox" v-model="switchcheckboxCustomRule" id="switchcheckboxCustomRule">
                          <label for="switchcheckboxCustomRule"></label>
                        </div>
                      </div>
                    </div>
                    <div class="appType--micro appType--quiet italicized" v-if="customRulesData.edition =='isp'">
                      Note: If it is 'ON', sub-zones can not change the custom rules.
                    </div>
                    <div class="appType--micro appType--quiet italicized" v-else>
                      Note: If it is 'ON', sub-accounts can not change the custom rules.
                    </div>
                  </div>
                </div>
              </div>
              <div class="jazeModal__footer">
                <div class="jazeButtonGroup jazeButtonGroup--cozy">
                  <button class="jazeButton jazeButton--secondary" type="button" @click="subZoneModalShow=false">Cancel</button>
                  <button class="jazeButton jazeButton--primary submitForm" type="button" @click="allowSubZones">Yes</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <SuccessToaster :successMessage='successMessage' :sucesstoasterShow='successtoaster' v-if='successtoaster'></SuccessToaster>
    <ErrorToaster :errorMessage='errorMessage' :errortoasterShow='errortoaster' v-if='errortoaster'></ErrorToaster>
    <PushToSubZone v-if="pushModal" :selected='selected' :edition="customRulesData.edition" @extend-clicked-push-detail="onclickclose" :subAccounts="subaccDetailsGetter" :from="'customRules'" :modalType="'subAccountsOnly'"></PushToSubZone>
  </div>
</template>

<script>
import { ref,computed,watch,watchEffect,onMounted,onBeforeUnmount } from 'vue'
import Treeselect from "jaze-vue3-treeselect";
import SuccessToaster from "@/components/Toaster/SuccessToaster.vue";
import ErrorToaster from "@/components/Toaster/ErrorToaster.vue";
import PushToSubZone from "@/components/Profiles/PushToSubZoneModal.vue";
;
import { storeToRefs } from "pinia";
import { usesettingsStore } from '@/store/settingsstore'
import { uselayoutStore } from '@/store/layoutstore'
export default {
  setup() {
    const layoutStore = uselayoutStore()
    const { subaccDetails } = storeToRefs(layoutStore)
    const subaccDetailsGetter= computed(() => subaccDetails.value);
    // const customRulesData = ref( [] )
    const ruleFieldLabel = ref( "" )
    const actionPlaceHolder = ref( "Enter Value" )
    const formClass = ref( "" )
    const errorShow = ref( false )
    const errorMessage = ref( "" )
    const successtoaster = ref( false )
    const successMessage = ref( "" )
    const errortoaster = ref( false )
    const customRuleAddEditModal = ref( false )
    const actionField = ref( true )
    const deleteConfirmModal = ref( false )
    const idToDelete = ref( "" )
    const nameToDelete = ref( "" )
    const action = ref( "Add" )
    const ruleId = ref( "" )
    const ruleName = ref( "" )
    const ruleField = ref( "" )
    const ruleSymbol = ref( "" )
    const ruleValue = ref( "" )
    const ruleAction = ref( "" )
    const ruleActionValues = ref( "" )
    const ruleStatus = ref( "" )
    //const subzoneType = ref( "" )
    const subZoneModalShow = ref( false )
    const selectedAccVal = ref( null )
    const subAccOptions = ref( [
            {
              id: "selectAll",
              label: "Select All",
              children: []
            }
          ] )
    const switchcheckboxCustomRule = ref( false )
    const pushModal = ref( false )
    const selected = ref( [] )
    const subAccounts = ref( [] )

    const settingsStore = usesettingsStore()
    const { customRulesData } = storeToRefs(settingsStore)
    const customRulesGetter = computed(() => customRulesData.value)

    const customRulesToDisplay = computed(() => {
      if (
        customRulesData.value.customRules != undefined &&
        customRulesData.value.customRules.length != 0
      ) {
        for (let i = 0; i < customRulesData.value.customRules.length; i++) {
          var label = getLabelNameFromId(
            customRulesData.value.customRules[i].RequestAccessRule.field
          );
          customRulesData.value.customRules[i].label = label;
          customRulesData.value.customRules[
            i
          ].name = customRulesData.value.customRules[i].RequestAccessRule.name
            .toLowerCase()
            .trim();
          customRulesData.value.customRules[
            i
          ].status = customRulesData.value.customRules[
            i
          ].RequestAccessRule.status
            .toLowerCase()
            .trim();
          customRulesData.value.customRules[
            i
          ].symbol = customRulesData.value.customRules[
            i
          ].RequestAccessRule.symbol;
          customRulesData.value.customRules[
            i
          ].value = customRulesData.value.customRules[i].RequestAccessRule.value;
          customRulesData.value.customRules[
            i
          ].action = customRulesData.value.customRules[
            i
          ].RequestAccessRule.action;
          customRulesData.value.customRules[
            i
          ].field = customRulesData.value.customRules[i].RequestAccessRule.field;
          var labelEncoded = getLabelNameFromIdJsonEncoded(
            customRulesData.value.customRules[i].RequestAccessRule.action_values
          );
          customRulesData.value.customRules[i].labelEncoded = labelEncoded;
        }
      }
      return customRulesData.value.customRules;
    })

    onMounted(() => {
      getCustomRulesData();
    })
    onBeforeUnmount(() => {
      settingsStore.clearSettingsState();
    })

    watchEffect(() => {
      customRulesData.value = customRulesGetter.value;
      if (
        customRulesData.value.accountsArr != undefined &&
        customRulesData.value.accountsArr.length != 0
      ) {
        for (let i = 0; i < customRulesData.value.accountsArr.length; i++) {
          var deactivated = customRulesData.value.accountsArr[i].deactivated == 0 ? " (Deactivated)" : "";
          subAccOptions.value[0].children.push({
            id: customRulesData.value.accountsArr[i].id,
            label: customRulesData.value.accountsArr[i].name + deactivated,
          });
        }
      }
      if (
        customRulesData.value != undefined &&
        customRulesData.value.subAccountsCanNotChangeCustomRules == "Yes"
      ) {
        switchcheckboxCustomRule.value = true;
      }
    })

    const getCustomRulesData = () => {
      // $store.dispatch("fetchCustomRulesData");
      settingsStore.fetchCustomRulesData();
    }
    const getLabelNameFromId = (id, type = null) => {
      if (type == "fromSelect") {
        ruleFieldLabel.value = "";
      }
      for (let i = 0; i < customRulesData.value.requestAccess.length; i++) {
        if (
          customRulesData.value.requestAccess[i].RequestAccessCustomField.id ==
          id
        ) {
          var label = customRulesData.value.requestAccess[i]
            .RequestAccessCustomField.label_name;
          if (type == "fromSelect") {
            ruleFieldLabel.value = label;
          } else {
            return label;
          }
        }
      }
      if (type !== "fromSelect") {
        return id;
      }
    }
    const getLabelNameFromIdJsonEncoded = (ids) => {
      if (ids.length != 0) {
        var idsAll = JSON.parse(ids);
        var labelName = [];
        for (let i = 0; i < idsAll.length; i++) {
          labelName.push(idsAll[i]);
        }
        return labelName.join("");
      } else {
        return "";
      }
    }
    const hideShowTextField = (event) => {
      var selected = event.target.value;
      if (
        selected == "excluded_in_revenue" ||
        selected == "excluded_in_discount" ||
        selected == "include_in_revenue"||
        selected == "exclude_fixed_rate"
      ) {
        actionField.value = false;
      } else {
        actionField.value = true;
      }
      if (selected == "exclude_cess") {
        actionPlaceHolder.value = "Enter CESS Description";
      } else {
        actionPlaceHolder.value = "Enter Value";
      }
    }
    const addRule = () => {
      ruleId.value = "";
      ruleName.value = "";
      ruleField.value = "";
      ruleFieldLabel.value = "";
      actionPlaceHolder.value = "Enter Value";
      ruleSymbol.value = "";
      ruleValue.value = "";
      ruleAction.value = "";
      ruleActionValues.value = "";
      ruleStatus.value = "";
      customRuleAddEditModal.value = true;
    }
    const addEditCustomRule = (e) => {
      e.preventDefault();
      successtoaster.value = false;
      errortoaster.value = false;
      var form = document.getElementById('addEditRuleForm');
      const formData = new FormData(form);
      const postData = {};
      for (let [key, val] of formData.entries()) {
        Object.assign(postData, { [key]: val });
      }
      var status = checkForm(postData);
      if (status == "error") {
        formClass.value = "is-form-validation-visible";
        return;
      }
      postData.fieldId = ruleId.value;
      errorShow.value = false;
      // $store.dispatch("addCustomRuleSubmit", postData).then(
      settingsStore.addCustomRuleSubmit(postData).then(  
        response => {
          customRuleAddEditModal.value = false;
          successtoaster.value = true;
          successMessage.value = response;
          setTimeout(
            function() {
              successtoaster.value = false;
            }.bind(this),
            5000
          );
        },
        error => {
          customRuleAddEditModal.value = false;
          errortoaster.value = true;
          errorMessage.value = error;
          setTimeout(
            function() {
              errortoaster.value = false;
            }.bind(this),
            1500
          );
          console.log(error);
        }
      );
    }
    const checkForm = (postData) => {
      if (postData.name == "") {
        errorMessage.value = "Name is Required";
        errorShow.value = true;
        return "error";
      }
      if (postData.value == "") {
        errorMessage.value = "Value is Required";
        errorShow.value = true;
        return "error";
      }
      if (postData.action == "" || postData.action == null) {
        errorMessage.value = "Action is Required";
        errorShow.value = true;
        return "error";
      }
      if (postData.action != undefined && postData.action !== "exclude_cess") {
        if (postData.symbol == undefined || postData.symbol == "") {
          errorMessage.value = "Symbol is Required";
          errorShow.value = true;
          return "error";
        }
      }
    }
    const editCustomRule = (rule) => {
      action.value = "Edit";
      ruleId.value = rule.RequestAccessRule.id;
      ruleName.value = rule.RequestAccessRule.name;
      ruleField.value = rule.RequestAccessRule.field;
      ruleFieldLabel.value = rule.label;
      ruleSymbol.value = rule.RequestAccessRule.symbol;
      ruleValue.value= rule.RequestAccessRule.value;
      ruleAction.value = rule.RequestAccessRule.action;
      if (rule.RequestAccessRule.action == "exclude_cess") {
        actionPlaceHolder.value = "Enter CESS Description";
      }
      ruleActionValues.value = rule.labelEncoded;
      ruleStatus.value = rule.RequestAccessRule.status;
      customRuleAddEditModal.value = true;
    }
    const duplicateCustomRule = (rule) => {
      action.value = "Duplicate";
      ruleId.value = "";
      ruleName.value = rule.RequestAccessRule.name;
      ruleField.value = rule.RequestAccessRule.field;
      ruleFieldLabel.value = rule.label;
      ruleSymbol.value = rule.RequestAccessRule.symbol;
      ruleValue.value = rule.RequestAccessRule.value;
      ruleAction.value = rule.RequestAccessRule.action;
      if (rule.RequestAccessRule.action == "exclude_cess") {
        actionPlaceHolder.value = "Enter CESS Description";
      }
      ruleActionValues.value = rule.labelEncoded;
      ruleStatus.value = rule.RequestAccessRule.status;
      customRuleAddEditModal.value = true;
    }
    const deleteCustomRule = (rule) => {
      idToDelete.value = rule.RequestAccessRule.id;
      nameToDelete.value = rule.RequestAccessRule.name;
      deleteConfirmModal.value = true;
    }
    const deletePeriodConfirm = () => {
      var data = {
        fieldId: idToDelete.value
      };
      // $store.dispatch("deleteCustomRuleSubmit", data).then(
      settingsStore.deleteCustomRuleSubmit(data).then(  
        response => {
          deleteConfirmModal.value = false;
          successtoaster.value = true;
          successMessage.value = response;
          setTimeout(
            function() {
              successtoaster.value = false;
            }.bind(this),
            5000
          );
        },
        error => {
          deleteConfirmModal.value = false;
          errortoaster.value = true;
          errorMessage.value = error;
          setTimeout(
            function() {
              errortoaster.value = false;
            }.bind(this),
            1500
          );
          console.log(error);
        }
      );
    }
    const allowSubZones = () => {
      successtoaster.value = false;
      errortoaster.value = false;
      if (switchcheckboxCustomRule.value == true) {
        var check = "on";
      } else {
        var check = "off";
      }
      var data = {
        switchCheckBoxValue: check,
        attribute: "subAccountsCanNotChangeCustomRules",
        type: "custom_rules"
      };
      // $store.dispatch("saveSubAccountPermissionSettings", data).then(
      settingsStore.saveSubAccountPermissionSettings(data).then(  
        response => {
          subZoneModalShow.value = false;
          successtoaster.value = true;
          successMessage.value = response;
          setTimeout(
            function() {
              successtoaster.value = false;
            }.bind(this),
            2000
          );
        },
        error => {
          errortoaster.value = true;
          errorMessage.value = error;
          setTimeout(
            function() {
              errortoaster.value = false;
            }.bind(this),
            15000
          );
          console.log(error);
        }
      );
    }
    const onclickclose = () => {
      pushModal.value = false;
    }
    const pushZonefn = () => {
      pushModal.value = !pushModal.value;
    }

    return {
      settingsStore,
      customRulesGetter: customRulesData,
      subaccDetailsGetter,
      customRulesToDisplay,
      customRulesData,
      ruleFieldLabel,
      actionPlaceHolder,
      formClass,
      errorShow,
      errorMessage,
      successtoaster,
      successMessage,
      errortoaster,
      customRuleAddEditModal,
      actionField,
      deleteConfirmModal,
      idToDelete,
      nameToDelete,
      action,
      ruleId,
      ruleName,
      ruleField,
      ruleSymbol,
      ruleValue,
      ruleAction,
      ruleActionValues,
      ruleStatus,
      //subzoneType,
      subZoneModalShow,
      selectedAccVal,
      subAccOptions,
      switchcheckboxCustomRule,
      pushModal,
      selected,
      subAccounts,
      getCustomRulesData,
      getLabelNameFromId,
      getLabelNameFromIdJsonEncoded,
      hideShowTextField,
      addRule,
      addEditCustomRule,
      checkForm,
      editCustomRule,
      duplicateCustomRule,
      deleteCustomRule,
      deletePeriodConfirm,
      allowSubZones,
      onclickclose,
      pushZonefn,
    }
  },
  components: {
    Treeselect,
    SuccessToaster,
    ErrorToaster,
    PushToSubZone,
  },
};
</script>
